import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * Used for get character data or whole characters list of certain retailer+rtl combination
 */

export const apiUrlGetRetailerCharacters = ({ characterId }: { characterId?: string }) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `${retailerSlug}/${retailerLocationSlug}/characters/character/${characterId ? `${characterId}/` : ''}`;
};

/**
 * Used for get character ESSENCES data or whole ESSENCES list of certain retailer+rtl combination
 */
export const apiUrlGetCharacterEssences = ({ characterId }: { characterId?: string }) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `${retailerSlug}/${retailerLocationSlug}/characters/essence-score/${characterId ? `?character_identifier=${characterId}` : ''}`;
};

/**
 * Used for get character FOOD PAIRING data or whole FOOD PAIRING list of certain retailer+rtl combination
 */
export const apiUrlGetCharacterFoodPairing = ({ characterId }: { characterId?: string }) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `${retailerSlug}/${retailerLocationSlug}/products/attrs/food-pairing/${characterId ? `?character_identifier=${characterId}` : ''}`;
};
