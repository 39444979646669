import cn from 'classnames';
import { FC } from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import slateBackgroundImage from '@app/pmi/src/assets/media/V2/v2_slate_background.png';
import { GA_EVENT_CALL_TO_ACTION, trackCallToAction } from '@app/pmi/src/comms/events';

import { TProductInstance } from '@lib/core/products/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  isCoolingEssencePresent,
  reOrderEssencesForIQOS3Designs,
  reOrderEssencesForIQOS4Designs,
} from '@lib/tools/shared/pmi/essences';
import { HEETS_EU, TEREA_EU } from '@lib/tools/shared/pmi/essences/consts';
import { redirectToProductPage, retailerProductLocationTags } from '@lib/tools/shared/pmi/products';
import { HEART, HEETS, SENTIA, STAR, TEREA, THUMB, TRENDING, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useEqualElementsHeightV2 } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import AddToCart from '@components/pmi/src/organisms/Products/AddToCart/AddToCart';
import AromaNote from '@components/pmi/src/organisms/Products/AromaNote/AromaNote';
import EssencesBars from '@components/pmi/src/organisms/Products/EssencesBars/BarEssences';
import EssencesEU from '@components/pmi/src/organisms/Products/EssencesEU/EssencesEU';
import Feedback from '@components/pmi/src/organisms/Products/Feedback/Feedback';
import NicotineLevels from '@components/pmi/src/organisms/Products/NicotineLevels/NicotineLevels';
import TechnicalCaption from '@components/pmi/src/organisms/Results/TechnicalCaption/TechnicalCaption';
import { getTechnicalCaptionText, getTobaccoText } from '@components/pmi/src/utils';

export type TechnicalCaptionIcon = typeof HEART | typeof STAR | typeof THUMB | typeof TRENDING;

interface Props {
  gprl: TProductInstance;
  isAromaNotesDisabledAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isAddToCartAddon: boolean;
  isEnableBuyEcommerceAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTechnicalCaptionEnable: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  isMentholDisclaimerAddon: boolean;
  isVeev?: boolean;
  isFusePage?: boolean;
  intl: IntlShape;
  technicalCaptionIconName?: TechnicalCaptionIcon;
  technicalCaptionTextNumber?: number;
  selectedProductCategory: string;
  nicotineLevelData?: {
    description: string;
    name: string;
  }[];
  minQuantity: number;
  maxQuantity: number;
  isRestrictedRecommendationAddon: boolean;
  isThreeProductsShown?: boolean;
}

const RecommendedProduct: FC<Props> = ({
  gprl,
  intl,
  isAromaNotesDisabledAddon,
  isTobaccoExperienceHiddenAddon,
  isAromaNoteSecondaryAddon,
  isAddToCartAddon,
  isEnableBuyEcommerceAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isTereaHideEssencesAddon,
  isTechnicalCaptionEnable,
  technicalCaptionIconName,
  technicalCaptionTextNumber,
  selectedProductCategory,
  isVeev,
  nicotineLevelData,
  maxQuantity,
  minQuantity,
  isFusePage,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isMentholDisclaimerAddon,
  isRestrictedRecommendationAddon,
  isThreeProductsShown,
}) => {
  useEqualElementsHeightV2([
    'ta-v2-recommended-product-technical-caption-container',
    'ta-v2-recommended-product-name',
    'ta-v2-recommended-product-caption',
    'ta-v2-recommended-product-description',
    'ta-v2-recommended-product-essences-block',
    'ta-v2-recommended-product-essences-bar',
    'ta-v2-recommended-product-tobacco',
    'ta-v2-recommended-product-footer-block',
    'ta-v2-recommended-product-veev-nicotine-level-block',
    'ta-v2-recommended-product-gprl-description',
  ]);

  const { image, currency, price, url, description: gprlDescription } = gprl;
  const {
    essences,
    name,
    product_category: productCategory,
    caption,
    description: productDescription,
    attributes,
    aromas,
    identifier,
  } = gprl.product;
  const {
    background_image_v2: backgroundImage,
    restricted_background_image_v2: restrictedBackgroundImage,
    product_type: type,
    color_primary: colorPrimary,
    color,
    is_pearl: isPearl,
  } = attributes;
  const isPearlType = isPearl === 'true';

  const { isEuClassicTobaccoEssencesTag } = retailerProductLocationTags(gprl);

  const isCooling = isMentholDisclaimerAddon && isCoolingEssencePresent(essences);

  let currentEssences = essences;
  let currentProductCategory = '';
  let currentColor = '';
  let currentBackground = '';

  if (selectedProductCategory === VEEV) {
    if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage})`;
    } else {
      currentBackground = `url(${backgroundImage})`;
    }
  }

  if (selectedProductCategory === TEREA) {
    currentProductCategory = type;
    currentColor = colorPrimary;
    if (isRestrictedProductCardsAddon) {
      currentBackground = '#34303d';
    } else if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage}) left top/100% no-repeat, 0% 0%/auto  #34303d`;
    } else {
      currentBackground = `url(${backgroundImage}) left top/100% no-repeat, 0% 0%/auto  #34303d`;
    }
    if (isEuClassicTobaccoEssencesTag) {
      currentEssences = essences.filter(essence => essence.group === TEREA_EU);
    } else {
      currentEssences = reOrderEssencesForIQOS4Designs({
        essences,
        isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
        isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
        isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
      });
    }
  }
  if (selectedProductCategory === HEETS) {
    currentProductCategory = productCategory;
    currentColor = color;
    if (isRestrictedProductCardsAddon) {
      currentBackground = `url(${slateBackgroundImage}) left top/contain repeat`;
    } else if (isRestrictedRecommendationAddon && !!restrictedBackgroundImage) {
      currentBackground = `url(${restrictedBackgroundImage}) left top/contain no-repeat, url(${slateBackgroundImage}) left top/contain repeat`;
    } else {
      currentBackground = `url(${backgroundImage}) left top/contain no-repeat, url(${slateBackgroundImage}) left top/contain repeat`;
    }
    if (isEuClassicTobaccoEssencesTag) {
      currentEssences = essences.filter(essence => essence.group === HEETS_EU);
    } else {
      currentEssences = reOrderEssencesForIQOS3Designs({
        essences,
        isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
        isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
        isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
      });
    }
  }

  return (
    <>
      {isVeev ? (
        <>
          <div
            className={cn('ta-v2-recommended-product', {
              'ta-v2-recommended-product-veev': isVeev,
            })}
          >
            <div className="ta-v2-recommended-product-veev-image-block" style={{ backgroundImage: currentBackground }}>
              <img alt="recommended product veev img" className="ta-v2-recommended-product-veev-image" src={image} />
            </div>
            <div className="ta-v2-recommended-product-veev-content-block">
              <div className="font-ta-v2-custom-small-header-bold text-uppercase">
                {productCategory === 'veev' ? 'veev one' : productCategory}
              </div>

              <div className="ta-v2-recommended-product-name font-ta-v2-custom-large-header-bold">{name}</div>

              {!isProductShortDescriptionHiddenAddon && (
                <div className="ta-v2-recommended-product-caption font-ta-v2-global-body-large font-weight-bold">
                  {caption}
                </div>
              )}

              {!isProductLongDescriptionHiddenAddon && (
                <div className="ta-v2-recommended-product-description font-ta-v2-global-regulatory-text-medium margin-top-8px">
                  {productDescription}
                </div>
              )}
            </div>
            <div className="ta-v2-recommended-product-veev-nicotine-level-block">
              {nicotineLevelData?.length ? (
                <div className="ta-v2-recommended-product-veev-nicotine-level-wrapper">
                  <NicotineLevels nicotineLevelData={nicotineLevelData} />
                </div>
              ) : null}
            </div>
            <div className="ta-v2-recommended-product-gprl-description">
              {gprlDescription && (
                <div className="ta-v2-recommended-product-veev-gprl-description-text font-ta-v2-global-regulatory-text-medium">
                  {gprlDescription}
                </div>
              )}
            </div>
            <div className="ta-v2-recommended-product-footer-block">
              {isEnableBuyEcommerceAddon ? (
                <CTAButton
                  isDark
                  btnText={<LocaleFragment message={localeV2.common.buyEcommerce} />}
                  className="ta-v2-recommended-product-footer-btn"
                  onBtnClick={() => {
                    trackCallToAction(`${GA_EVENT_CALL_TO_ACTION.BUY}`);
                    redirectToProductPage(url);
                  }}
                />
              ) : (
                <>
                  {isAddToCartAddon ? (
                    <AddToCart
                      currency={currency}
                      ecommerceID={gprl.ecommerce_id}
                      isVeev={isVeev}
                      maxQuantity={maxQuantity}
                      minQuantity={minQuantity}
                      optionID={gprl.tags?.option_id}
                      price={price}
                      variantID={gprl.tags?.variant_id}
                    />
                  ) : (
                    <Feedback identifier={identifier} isFusePage={isFusePage} />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ background: currentBackground }}
            className={cn('ta-v2-recommended-product', {
              isThreeProductsShown,
            })}
          >
            {isTechnicalCaptionEnable ? (
              <div className="ta-v2-recommended-product-technical-caption-container">
                <TechnicalCaption
                  icon={technicalCaptionIconName}
                  isThreeProductsShown={isThreeProductsShown}
                  text={getTechnicalCaptionText(intl, technicalCaptionTextNumber)}
                />
              </div>
            ) : null}
            <div className="ta-v2-recommended-product-content-block">
              <div
                className={cn('ta-v2-recommended-product-content-block-image-wrapper', {
                  'margin-top-32px': isTechnicalCaptionEnable,
                })}
              >
                <img
                  alt="recommended product img"
                  className="ta-v2-recommended-product-content-block-image"
                  src={image}
                />
              </div>

              <div className="font-ta-v2-custom-small-header-bold text-uppercase">{currentProductCategory}</div>

              <div className="ta-v2-recommended-product-name font-ta-v2-custom-large-header-bold">{name}</div>

              {!isProductShortDescriptionHiddenAddon && (
                <div className="ta-v2-recommended-product-caption font-ta-v2-global-body-large font-weight-bold">
                  {`${caption}${isCooling ? '*' : ''}`}
                </div>
              )}

              {!isTobaccoExperienceHiddenAddon ? (
                <div className="ta-v2-recommended-product-tobacco font-ta-v2-global-body-large font-weight-bold">
                  <LocaleFragment message={getTobaccoText(gprl)} />
                </div>
              ) : null}

              {!isProductLongDescriptionHiddenAddon && (
                <div className="ta-v2-recommended-product-description font-ta-v2-global-regulatory-text-medium margin-top-8px">
                  {productDescription}
                </div>
              )}
              <div
                className="ta-v2-recommended-product-essences-block"
                style={{ backgroundColor: isTereaHideEssencesAddon && !isEuClassicTobaccoEssencesTag && 'transparent' }}
              >
                {isEuClassicTobaccoEssencesTag ? (
                  <EssencesEU
                    isHorizontalOrientation
                    color={currentColor}
                    name={currentEssences[0]?.name}
                    score={+currentEssences[0]?.score}
                  />
                ) : (
                  <div className="ta-v2-recommended-product-essences-bar w-100">
                    {isTereaHideEssencesAddon ? null : <EssencesBars color={currentColor} essences={currentEssences} />}
                  </div>
                )}
                {isAromaNotesDisabledAddon || isEuClassicTobaccoEssencesTag || type === SENTIA ? null : (
                  <AromaNote
                    isHorizontalOrientation
                    description={aromas?.[0]?.description}
                    isPearlType={isPearlType}
                    image={
                      isAromaNoteSecondaryAddon && aromas?.[0]?.secondary_image
                        ? aromas?.[0]?.secondary_image
                        : aromas?.[0]?.image
                    }
                  />
                )}
              </div>
              <div className="ta-v2-recommended-product-gprl-description">
                {gprlDescription && (
                  <div className="margin-top-16px font-ta-v2-global-regulatory-text-medium">{gprlDescription}</div>
                )}
              </div>
            </div>
            <div className="ta-v2-recommended-product-footer-block">
              {isEnableBuyEcommerceAddon ? (
                <CTAButton
                  isDark
                  btnText={<LocaleFragment message={localeV2.common.buyEcommerce} />}
                  className="ta-v2-recommended-product-footer-btn"
                  onBtnClick={() => {
                    redirectToProductPage(url);
                  }}
                />
              ) : (
                <>
                  {isAddToCartAddon ? (
                    <AddToCart
                      currency={currency}
                      ecommerceID={gprl.ecommerce_id}
                      maxQuantity={maxQuantity}
                      minQuantity={minQuantity}
                      optionID={gprl.tags?.option_id}
                      price={price}
                      variantID={gprl.tags?.variant_id}
                    />
                  ) : (
                    <Feedback
                      identifier={identifier}
                      isFusePage={isFusePage}
                      isThreeProductsShown={isThreeProductsShown}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default injectIntl(RecommendedProduct);
