import {
  apiUrlAllProducts,
  apiUrlGetProduct,
  apiUrlLimitedProducts,
  apiUrlSearchedProducts,
} from '@lib/core/products/slices/urls';
import { IApiUrlGetProductProps, TProductInstance } from '@lib/core/products/types';
import {
  ASSOCIATED_RETAILER_SLUG_QUERY,
  GPRL_CHARACTER_QUERY,
  GPRL_PROMOTIONS_QUERY,
  PREFERENCES_QUERY,
  PRODUCT_NAME_QUERY,
} from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { IProductsResponse } from '@lib/core/service/types/interface';
import { productsSuggestionApiUrlCreator } from '@lib/core/users/slices/urls';
import { IProductsLimitedRequestData } from '@lib/tools/productCatalog/types/types';
import { FILTER_TYPE_ORDERING, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';

export interface IRequestParams {
  offset?: number;
  limit?: number;
  [GPRL_CHARACTER_QUERY]?: string;
  [PRODUCT_CATEGORY_QUERY]?: string;
  [GPRL_PROMOTIONS_QUERY]?: string;
  [PREFERENCES_QUERY]?: string;
  [FILTER_TYPE_ORDERING]?: string;
  [ASSOCIATED_RETAILER_SLUG_QUERY]?: string;
}

const getProductData = ({
  productId,
  customRetailerSlug,
  customRetailerLocationSlug,
}: IApiUrlGetProductProps): TProductInstance => {
  const requestUrl = apiUrlGetProduct({ customRetailerLocationSlug, customRetailerSlug, productId });

  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
  });
};

// extended
const getProductsListData = (params: IRequestParams): IProductsResponse => {
  const paramsToBeSent = { ...params };
  if (paramsToBeSent[GPRL_CHARACTER_QUERY]) {
    paramsToBeSent[GPRL_CHARACTER_QUERY].split('-').join(',');
  }
  const requestUrl = paramsToBeSent[PRODUCT_NAME_QUERY] ? apiUrlSearchedProducts() : apiUrlAllProducts();
  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
    params: paramsToBeSent,
  });
};

// limited
const getLimitedProductsListData = (params: IRequestParams): IProductsLimitedRequestData => {
  const paramsToBeSent = { ...params };
  if (paramsToBeSent[GPRL_CHARACTER_QUERY]) {
    paramsToBeSent[GPRL_CHARACTER_QUERY].split('-').join(',');
  }
  const requestUrl = paramsToBeSent[PRODUCT_NAME_QUERY] ? apiUrlSearchedProducts() : apiUrlLimitedProducts();
  return request(requestUrl, {
    params: paramsToBeSent,
  });
};

const getTastePathProductsData = params => {
  const state = store.getState();
  const productCategory = selectServiceProductCategory(state);

  const requestUrl = productsSuggestionApiUrlCreator({ productCategory });

  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
    params,
  });
};

export { getProductData, getProductsListData, getTastePathProductsData, getLimitedProductsListData };
