import { createSlice } from '@reduxjs/toolkit';

import { experiencesApiUrlCreator } from '@lib/core/experiences/slices/urls';
import { TExperience } from '@lib/core/experiences/types';
import { TProductCategory } from '@lib/core/products/types';
import { PRODUCER_QUERY } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';

type TPayloadArgs = {
  productCategory: TProductCategory;
  producer?: string;
};

interface IState {
  error: string;
  data: TExperience[];
  isLoading: boolean;
}

const initialState: IState = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetExperiences = createTypedAsyncThunk<TExperience[], TPayloadArgs>(
  'actionGetExperiences',
  async ({ productCategory, producer = '' }) => {
    const params = {
      ...(productCategory && { [PRODUCT_CATEGORY_QUERY]: productCategory }),
      ...(producer && { [PRODUCER_QUERY]: producer }),
    };

    const { results = [] } = await request(experiencesApiUrlCreator(), { params });
    return results;
  },
);

export const experiencesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetExperiences.pending, state => {
      state.data = [];
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetExperiences.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(actionGetExperiences.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
  initialState,
  name: 'experiences',
  reducers: {},
});

export default experiencesSlice.reducer;
