import { createSlice } from '@reduxjs/toolkit';

import { TParsedProductInstance, TProductInstance } from '@lib/core/products/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectProductFeedbackData } from '@lib/core/users/selectors/productFeedback';
import { productFeedbackDataApiUrlCreator } from '@lib/core/users/slices/urls';
import { parseError } from '@lib/tools/shared/helpers';

export type TProductFeedbackValue = 1 | 2 | 3 | 4 | 5;

export type TProductFeedback = {
  feedback: TProductFeedbackValue;
  product: TProductInstance;
};

export interface IProductFeedbackDataWithFullProductData {
  feedback: TProductFeedbackValue;
  product: TParsedProductInstance;
}
export interface IProductFeedbackState {
  data: TProductFeedback[];
  isLoading: boolean;
  error: string;
  lastUpdatedFeedbackId: string;
}

const initialState: IProductFeedbackState = {
  data: [],
  error: null,
  isLoading: false,
  lastUpdatedFeedbackId: '',
};

export const actionGetProductFeedback = createTypedAsyncThunk(
  'users/productFeedback/actionGetProductFeedback',
  async (): Promise<{ results: TProductFeedback[] }> => await request(productFeedbackDataApiUrlCreator()),
);

export const actionUpdateProductFeedback = createTypedAsyncThunk(
  'users/productFeedback/actionUpdateProductFeedback',
  async ({ feedback, productId }: { feedback: number; productId: string }, { getState, rejectWithValue }) => {
    const state = getState();
    const feedbackData = selectProductFeedbackData(state);

    const isFeedbackExist = feedbackData.some(item => item.product?.product?.identifier === productId);
    const method = isFeedbackExist ? REQUEST_METHODS.PATCH : REQUEST_METHODS.POST;

    try {
      const response = await request(
        productFeedbackDataApiUrlCreator(isFeedbackExist ? productId : ''),
        { method },
        { feedback, ...(!isFeedbackExist && { product: productId }) },
      );
      return { isFeedbackExist, response };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const productFeedbackSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(actionGetProductFeedback.pending, state => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(actionUpdateProductFeedback.pending, state => {
        state.error = '';
        state.isLoading = true;
        state.lastUpdatedFeedbackId = '';
      })
      .addCase(actionGetProductFeedback.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.results;
      })
      .addCase(actionUpdateProductFeedback.fulfilled, (state, action) => {
        const { isFeedbackExist, response } = action.payload;

        if (isFeedbackExist) {
          const { product, feedback } = response as {
            feedback: TProductFeedbackValue;
            product: string;
          };
          const feedbackToUpdate = state.data.find(item => item.product.product.identifier === product);

          feedbackToUpdate.feedback = feedback;
          state.lastUpdatedFeedbackId = product;
        } else {
          const productFeedback = response as TProductFeedback;

          state.data.push(productFeedback);
          state.lastUpdatedFeedbackId = productFeedback.product.product.identifier || '';
        }

        state.isLoading = false;
      })
      .addCase(actionGetProductFeedback.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(actionUpdateProductFeedback.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
  initialState,
  name: 'productFeedback',
  reducers: {},
});

export default productFeedbackSlice.reducer;
