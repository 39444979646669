import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  selectQuizDataId,
  selectUserQuizId,
  selectUserQuizSlug,
  selectUserQuizType,
} from '@lib/core/quizzes/selectors';
import {
  apiUrlPatchUserQuizAnswer,
  apiUrlPatchUserQuizComplete,
  apiUrlPatchUserQuizMetadata,
  apiUrlPostUserQuiz,
} from '@lib/core/quizzes/slices/urls';
import { TQuizType } from '@lib/core/quizzes/types';
import { TUserQuizDetail } from '@lib/core/quizzes/types/userQuiz';
import { QUIZ_TYPE_EXPERT, QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE } from '@lib/core/quizzes/utils/consts';
import { selectAppliedDesignSet, selectRetailerLocationId } from '@lib/core/retailers/selectors/retailerLocation';
import { REQUEST_METHODS, isApplicationPmi } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { uuidv4 } from '@lib/core/service/utils';
import { selectUserSessionId } from '@lib/core/users/selectors/user';
import { selectIsUserKioskAdmin } from '@lib/core/users/selectors/userKiosk';

export interface IUserQuizSlice {
  isFetching: boolean;
  data: TUserQuizDetail;
  error: string;
}
const initialState: IUserQuizSlice = {
  data: undefined,
  error: '',
  isFetching: false,
};

export const actionPostUserQuiz = createTypedAsyncThunk(
  'quizzes/userQuiz/actionPostUserQuiz',
  async ({ quizSlug, quizType }: { quizSlug: string; quizType: TQuizType }, { getState }) => {
    const retailerLocationId = selectRetailerLocationId(getState());
    const userSessionId = selectUserSessionId(getState());
    const quizTestDataId = selectQuizDataId(getState());
    const userQuizId = selectUserQuizId(getState());
    const config = { method: REQUEST_METHODS.POST };

    const quizTypesForUpdateCharacter = [QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE, QUIZ_TYPE_EXPERT];

    const shouldSkipCharacterUpdate = !quizTypesForUpdateCharacter.includes(quizType);

    const body: any = {
      quiz_test_data_id: quizTestDataId,
      retailer_location: retailerLocationId,
      test_session_id: userQuizId,
      ...(shouldSkipCharacterUpdate && { is_default: false }),
    };

    if (!isApplicationPmi) {
      body.user_session_id = userSessionId;
    }

    return await request(apiUrlPostUserQuiz(quizSlug), config, body);
  },
);

/**
 * Submit answer(s) on the `userQuiz`
 */
export const actionPatchUserQuizSubmitAnswer = createTypedAsyncThunk(
  'quizzes/userQuiz/actionPatchUserQuizSubmitAnswer',
  async ({ answerIds, questionId }: { answerIds: string[]; questionId: string }, { getState }): Promise<any> => {
    const userQuizId = selectUserQuizId(getState());
    const userQuizSlug = selectUserQuizSlug(getState());

    const body = {
      answers: answerIds,
      question: questionId,
    };
    const config = { method: REQUEST_METHODS.PATCH };

    const apiUrl = apiUrlPatchUserQuizAnswer(userQuizSlug, userQuizId);

    return await request(apiUrl, config, body);
  },
);

export const actionPatchUserQuizComplete = createTypedAsyncThunk<
  TUserQuizDetail & { itWillUpdateUserCharacter?: boolean },
  { userQuizType?: TQuizType }
>('quizzes/userQuiz/actionPatchUserQuizComplete', async ({ userQuizType }, { getState }) => {
  const retailerLocationId = selectRetailerLocationId(getState());
  const userQuizSlug = selectUserQuizSlug(getState());
  const userQuizId = selectUserQuizId(getState());
  const userSessionId = selectUserSessionId(getState());
  const { isDesignSetVinhoodApp } = selectAppliedDesignSet(getState());
  const { isUserQuizTypeMultiProduct, isUserQuizTypeTaste, isUserQuizTypeExpert } = selectUserQuizType(getState());

  const isUserKioskAdmin = selectIsUserKioskAdmin(getState());

  const isSetDefault =
    !isUserKioskAdmin &&
    (isDesignSetVinhoodApp ? isUserQuizTypeMultiProduct : isUserQuizTypeTaste || isUserQuizTypeExpert);

  const quizTypesForUpdateCharacter = [QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE, QUIZ_TYPE_EXPERT];

  const shouldSkipCharacterUpdate = !quizTypesForUpdateCharacter.includes(userQuizType);

  const body = {
    is_complete: true,
    is_default: !shouldSkipCharacterUpdate,
    retailer_location: retailerLocationId,
    set_default_test: isSetDefault,
    test_session_id: userQuizId,
    user_session_id: undefined,
  };

  if (!isApplicationPmi) {
    body.user_session_id = userSessionId;
  }

  const config = { method: REQUEST_METHODS.PATCH };

  const response = await request(apiUrlPatchUserQuizComplete(userQuizSlug, userQuizId), config, body);
  return !shouldSkipCharacterUpdate ? { ...response, itWillUpdateUserCharacter: true } : response;
});

/**
 * Tracking data on metadata field
 */
export const actionPatchUserQuizMetadata = createTypedAsyncThunk(
  'quizzes/userQuiz/actionPatchUserQuizMetadata',
  async (body: Record<string, any>, { getState }): Promise<any> => {
    const userQuizId = selectUserQuizId(getState());
    const userQuizSlug = selectUserQuizSlug(getState());

    return await request(
      apiUrlPatchUserQuizMetadata(userQuizSlug, userQuizId),
      {
        method: REQUEST_METHODS.PATCH,
      },
      body,
    );
  },
);

const userQuizSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(actionPostUserQuiz.fulfilled, actionPatchUserQuizComplete.fulfilled),
      (state, action) => {
        state.data = action.payload;
        state.error = '';
        state.isFetching = false;
      },
    );
    builder.addMatcher(
      isAnyOf(actionPostUserQuiz.pending, actionPatchUserQuizSubmitAnswer.pending, actionPatchUserQuizComplete.pending),
      state => {
        state.error = '';
        state.isFetching = true;
      },
    );
    builder.addMatcher(isAnyOf(actionPatchUserQuizSubmitAnswer.fulfilled), state => {
      state.error = '';
      state.isFetching = false;
    });
    builder.addMatcher(
      isAnyOf(
        actionPostUserQuiz.rejected,
        actionPatchUserQuizSubmitAnswer.rejected,
        actionPatchUserQuizComplete.rejected,
      ),
      (state, action) => {
        state.isFetching = false;
        state.error = action.error.message;
      },
    );
  },
  initialState,
  name: 'userQuiz',
  reducers: {
    actionGenerateUserQuizId: state => {
      return {
        ...state,
        data: {
          ...state.data,
          test_session_id: uuidv4(),
        },
      };
    },
    actionResetUserQuiz: () => initialState,
  },
});

export default userQuizSlice.reducer;

export const { actionGenerateUserQuizId, actionResetUserQuiz } = userQuizSlice.actions;
