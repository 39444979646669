import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import QuestionHeader from '@components/pmi/src/organisms/Header/Header';

interface Props {
  isHeetsBackgroundEnabled: boolean;
  currentPrimaryColor: string;
}

const QuizLoadingPage = ({ currentPrimaryColor, isHeetsBackgroundEnabled }: Props) => {
  return (
    <div className="ta-v2-ecommerce-quiz-loading-page">
      <QuestionHeader
        isHideProgressBar
        isLoading
        caption=""
        currentPrimaryColor={currentPrimaryColor}
        goBack={() => {}}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isShowBackButton={false}
        progressPercent={0}
        title=""
      />
      <div className="ta-v2-ecommerce-quiz-loading-page-load-container">
        <div className="ta-v2-ecommerce-quiz-loading-page-load-content-box">
          <div className="ta-v2-ecommerce-quiz-loading-icon">
            <svg fill="none" height="124" viewBox="0 0 124 124" width="124" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M62.096 105.923C86.3 105.923 105.922 86.3005 105.922 62.0965C105.922 37.8915 86.3 18.2695 62.096 18.2695C37.891 18.2695 18.269 37.8915 18.269 62.0965C18.269 86.3005 37.891 105.923 62.096 105.923Z"
                stroke="#34303D"
                strokeWidth="4"
              />
              <path
                d="M62.0001 32.1921C64.1941 32.1921 65.9731 30.4131 65.9731 28.2191C65.9731 26.0241 64.1941 24.2461 62.0001 24.2461C59.8061 24.2461 58.0271 26.0241 58.0271 28.2191C58.0271 30.4131 59.8061 32.1921 62.0001 32.1921ZM62.0001 99.7561C64.1941 99.7561 65.9731 97.9781 65.9731 95.7831C65.9731 93.5891 64.1941 91.8101 62.0001 91.8101C59.8061 91.8101 58.0271 93.5891 58.0271 95.7831C58.0271 97.9781 59.8061 99.7561 62.0001 99.7561ZM95.7821 65.9741C97.9771 65.9741 99.7551 64.1951 99.7551 62.0011C99.7551 59.8071 97.9771 58.0281 95.7821 58.0281C93.5881 58.0281 91.8091 59.8071 91.8091 62.0011C91.8091 64.1951 93.5881 65.9741 95.7821 65.9741ZM28.2181 65.9741C30.4121 65.9741 32.1911 64.1951 32.1911 62.0011C32.1911 59.8071 30.4121 58.0281 28.2181 58.0281C26.0231 58.0281 24.2451 59.8071 24.2451 62.0011C24.2451 64.1951 26.0231 65.9741 28.2181 65.9741Z"
                fill={currentPrimaryColor}
              />
            </svg>
            <div className="ta-v2-ecommerce-quiz-loading-icon-arrow-first" />
            <div className="ta-v2-ecommerce-quiz-loading-icon-arrow-second" />
          </div>

          <span className="font-ta-v2-title-4">
            <LocaleFragment message={localeV2.ecommerce.quizLoadingPageText} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuizLoadingPage;
