import { Dispatch, SetStateAction } from 'react';

interface HandleProps {
  answerId: string;
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  handleConfirmAnswer?: (answerId: string, callback: () => void) => void;
}

export const handleClick = ({
  maxAnswersChoices,
  answersArray,
  setAnswersArray,
  answerId,
  handleConfirmAnswer,
}: HandleProps) => {
  const isCanClick = answersArray.length < maxAnswersChoices || maxAnswersChoices >= 2;

  if (isCanClick) {
    if (maxAnswersChoices >= 2 && answersArray.length !== maxAnswersChoices && !answersArray.includes(answerId)) {
      setAnswersArray(current => [...current, answerId]);
    }

    if (maxAnswersChoices === 1) {
      setAnswersArray([answerId]);
      setTimeout(() => {
        handleConfirmAnswer(answerId, () => {
          setAnswersArray([]);
        });
      }, 500);
    }

    if (maxAnswersChoices >= 2 && answersArray.includes(answerId)) {
      setAnswersArray(current => current.filter(currentId => currentId !== answerId));
    }
  }
};
