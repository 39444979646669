import { defineMessages } from '@formatjs/intl';

export const localeV2 = {
  bestMatch: defineMessages({
    bestMatchBtnText: {
      defaultMessage: 'Continue Taste Analysis',
      id: 'v2.best_match.cta.continue',
    },
    bestMatchHeaderText: {
      defaultMessage: 'Based on what you currently use, this product can be a good match for you.',
      id: 'v2.best_match.text.title',
    },
    bestMatchNotSureText: {
      defaultMessage: 'Still not sure?',
      id: 'v2.best_match.text.not_sure',
    },
    bestMatchQuestionsLeftText: {
      defaultMessage: '{ noOfAnswersLeftString } more questions to complete',
      id: 'v2.best_match.text.questions_left',
    },
  }),
  bundles: defineMessages({
    bundleOneSubtitle: {
      defaultMessage: 'We think you might like these flavours.',
      id: 'v2.bundles.text.subtitle_one',
    },
    bundleOneTitle: {
      defaultMessage: 'Your current choice & mood gave us a hint!',
      id: 'v2.bundles.text.title_one',
    },
    bundleTwoSubtitle: {
      defaultMessage: 'Try these flavours or let us know you better in 4 additional questions.',
      id: 'v2.bundles.text.subtitle_two',
    },
    bundleTwoTitle: {
      defaultMessage: 'Looking for other alternatives?',
      id: 'v2.bundles.text.title_two',
    },
    continue: {
      defaultMessage: 'Find products matching your taste',
      id: 'v2.bundles.cta.continue',
    },
  }),
  common: defineMessages({
    back: {
      defaultMessage: 'Back',
      id: 'v2.quiz.cta.back',
    },
    buyEcommerce: {
      defaultMessage: 'Buy',
      id: 'v2.ecommerce.cta.buy',
    },
    healthWarning: {
      defaultMessage:
        'This product is not risk free and provides nicotine, which is addictive. Only for use by adults.',
      id: 'v2.common.text.health_warning',
    },
    nextQuestion: { defaultMessage: 'Next Question', id: 'v2.quiz.cta.next_question' },
    seeAllFlavours: {
      defaultMessage: 'See all flavours',
      id: 'v2.product.cta.see_all_flavours',
    },
    seeAllLevia: {
      defaultMessage: 'See all Levia',
      id: 'v2.product.cta.see_all_levia',
    },
    seeAllSentia: {
      defaultMessage: 'See all Sentia',
      id: 'v2.product.cta.see_all_sentia',
    },
    seeAllTerea: {
      defaultMessage: 'See all Terea',
      id: 'v2.product.cta.see_all_terea',
    },
    startOver: {
      defaultMessage: 'Start over',
      id: 'v2.result.cta.start_over',
    },
    tobaccoExperience: {
      defaultMessage: 'tobacco experience',
      id: 'v2.product.text.tobacco_experience',
    },
    tobaccoExperienceLevia: {
      defaultMessage: 'Flavour experience with Nicotine',
      id: 'v2.product.text.tobacco_experience_levia',
    },
    tobaccoExperiencePearl: {
      defaultMessage: 'tobacco experience with a capsule',
      id: 'v2.product.text.tobacco_experience_pearl',
    },
  }),
  customInfoPage: defineMessages({
    ctaText: {
      defaultMessage: 'Close',
      id: 'v2.custom_info.cta.close',
    },
    message: {
      defaultMessage:
        'Philip Morris GmbHAm\nHaag 14\n82166 Gräfelfing\nPhone: 0800-1114767 \nEmail: contact.de@iqos.com\n  \n  \nCommercial register no: HRB 49432\nRegistered at: Local Court Munich\nVAT ID: DE 129 444 528\nWEEE-Reg.-Nr. DE: 45546419\n  \nThe Philip Morris GmbH is represented by the management board: Markus Essing (Chairman), Jörg Zangen,Dimitrios Karampis, Amanda Lola, Torsten Albig, Markus Schöngassner, Jeannette Rohwer-Kahlmann.\n  \nChairman of the Supervisory Board: Werner Barth\n  \nNotice\nThe EU Commission offers the possibility for online dispute resolution on a platform which is provided by them. This platform is reachable under the external link [linkTo=custom-info-message-link]https://ec.europa.eu/consumers/odr/[linkTo=custom-info-message-link].\n  \nPhilip Morris does not take an active part in dispute resolutions at a consumer arbitration board.',
      id: 'v2.custom_info.text.body',
    },
  }),
  disclaimerPage: defineMessages({
    ctaText: {
      defaultMessage: 'Agree',
      id: 'v2.disclaimer.cta.agree',
    },
    firstPart: {
      defaultMessage:
        'All concepts in these materials are solely for the purpose of commercializing smoke-free products and are aimed exclusively at adults(minimum 18 years old) who would otherwise continue to smoke (LAS) or use other nicotine products (LAU).',
      id: 'v2.disclaimer.text.part_one',
    },
    secondPart: {
      defaultMessage:
        'Any mention of consumers, customers, members, vapers, vape users, users, target audience, or smokers, mean legal age (minimum18 years old) smokers, who would otherwise continue to smoke, or, legal age users, who have switched or are in the process of switching from cigarettes to smoke-free products (such as, IQOS or other similar products from PMI`s portfolio).',
      id: 'v2.disclaimer.text.part_two',
    },
    thirdPart: {
      defaultMessage:
        'These products are not risk free, and provide nicotine, which is addictive. Only for use by adults, who would otherwise continue to smoke.',
      id: 'v2.disclaimer.text.part_three',
    },
  }),
  ecommerce: defineMessages({
    quizLoadingPageText: {
      defaultMessage: 'Loading your taste questions',
      id: 'v2.ecommerce.text.quiz_loader',
    },
    resultLoadingPageText: {
      defaultMessage: 'Hold tight as we look for the best result for your questions',
      id: 'v2.ecommerce.text.result_loader',
    },
  }),
  landing: defineMessages({
    cta: { defaultMessage: "Let's begin", id: 'v2.landing.cta.begin' },
    customInfoPageLink: {
      defaultMessage: 'custom info page link',
      id: 'v2.landing.text.custom_info_link',
    },
    disclaimerLinkIqos: {
      defaultMessage: 'www.iqos.com',
      id: 'v2.landing.text.iqos_link',
    },
    disclaimerLinkPrivacy: {
      defaultMessage: 'privacy policy.',
      id: 'v2.landing.text.privacy_link',
    },
    disclaimerTextFirstTerea: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, in order to make further recommendations on TEREA flavours. This content does not imply that any TEREA variants contain, nor that their flavours are associated to the products used to determine your flavour profile. Your data will be processed in accordance with PMI',
      id: 'v2.landing.text.terea_disclaimer',
    },
    disclaimerTextHeets: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, in order to make further recommendations on HEETS flavours. This content does not imply that any HEETS variants contain, nor that their flavours are associated to the products used to determine your flavour profile. Your data will be processed in accordance to the PMI',
      id: 'v2.landing.text.heets_disclaimer',
    },
    disclaimerTextSecondTerea: {
      defaultMessage:
        'Susceptor warning: Do not ingest or disassemble TEREA™ sticks. This product contains a sharp metal part which can cause serious injury if swallowed. Keep out of reach of children. More information on',
      id: 'v2.landing.text.terea_susceptor_warning',
    },
    disclaimerTextVeev: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, to make further recommendations on VEEV ONE flavours. It does not imply that any products mentioned in the quiz are linked to VEEV ONE flavours. Your data will be processed in accordance to the PMI',
      id: 'v2.landing.text.veev_disclaimer',
    },
    subTitle: {
      defaultMessage: 'Discover your personal taste character using our flavour quiz and find your tobacco blend.',
      id: 'v2.landing.text.terea_subtitle',
    },
    title: { defaultMessage: 'Find your matching flavour.', id: 'v2.landing.text.terea_title' },
    veevSubTitle: {
      defaultMessage: 'Discover your personal taste character using our flavour quiz and find your VEEV ONE flavours.',
      id: 'v2.landing.text.veev_subtitle',
    },
    veevTitle: { defaultMessage: 'Find your matching flavour.', id: 'v2.landing.text.veev_title' },
  }),
  portfolio: defineMessages({
    closeBtn: {
      defaultMessage: 'Close',
      id: 'v2.portfolio.cta.close',
    },
    leviaProductTitle: {
      defaultMessage: 'Levia',
      id: 'v2.portfolio.text.levia',
    },
    tereaPortfolio: {
      defaultMessage: 'View Terea portfolio',
      id: 'v2.portfolio.cta.view_terea',
    },
    tereaProductTitle: {
      defaultMessage: 'Terea',
      id: 'v2.portfolio.text.terea',
    },
  }),
  product: defineMessages({
    addToCartBtn: { defaultMessage: 'Add to Cart', id: 'v2.ecommerce.cta.add_to_cart' },
    addToCartText: { defaultMessage: 'Per Pack', id: 'v2.ecommerce.text.per_pack' },
    addToCartTextVeev: { defaultMessage: 'Each pack contains 2 pods', id: 'v2.ecommerce.text.each_pack' },
    aromaNote: { defaultMessage: 'Aroma note', id: 'v2.product.text.aroma_note' },
    feedbackNo: { defaultMessage: 'No', id: 'v2.product.cta.feedback_no' },
    feedbackTitle: {
      defaultMessage: 'Is this a good match?',
      id: 'v2.product.text.feedback',
    },
    feedbackTitleThreeProducts: {
      defaultMessage: 'Are you interested in trying it?',
      id: 'v2.product.text.feedbackTitleThreeProducts',
    },
    feedbackUndo: { defaultMessage: 'Undo', id: 'v2.product.cta.feedback_undo' },
    feedbackYes: { defaultMessage: 'Yes', id: 'v2.product.cta.feedback_yes' },
    nicotineLevel: { defaultMessage: 'Nicotine Level', id: 'v2.product.text.nicotine_level' },
    onAClick: { defaultMessage: 'On a Click', id: 'v2.product.text.on_a_click' },
    onClickFeedback: {
      defaultMessage: 'Thank you for your feedback',
      id: 'v2.product.text.feedback_submitted',
    },
  }),
  quiz: defineMessages({
    answerSectionHeaderMultiple: {
      defaultMessage: 'Select Multiple',
      id: 'v2.quiz.text.select_multiple',
    },
    answerSectionHeaderSingle: { defaultMessage: 'Select One', id: 'v2.quiz.text.select_one' },
    availableProducts: {
      defaultMessage: 'Available Products',
      id: 'v2.quiz.text.available',
    },
    discontinuedProducts: {
      defaultMessage: 'Discontinued Products',
      id: 'v2.quiz.text.discontinued',
    },
    productReferenceChange: { defaultMessage: 'Change', id: 'v2.quiz.cta.reference_card_change' },
    productReferenceLinkAroma: {
      defaultMessage: 'What is flavour complexity?',
      id: 'v2.quiz.text.reference_card_complexity',
    },
    productReferenceLinkIntensity: {
      defaultMessage: 'What is taste intensity?',
      id: 'v2.quiz.text.reference_card_intensity',
    },
    qds8003AnswerSectionHeaderText: {
      defaultMessage: 'Choose your intensity',
      id: 'v2.quiz.text.intensity',
    },
    qds8003SliderText: {
      defaultMessage: 'Slide above to adjust the intensity',
      id: 'v2.quiz.text.intensity_slider',
    },
    qds8004AnswerSectionHeaderText: {
      defaultMessage: 'Choose your complexity',
      id: 'v2.quiz.text.complexity',
    },
    qds8004SliderText: {
      defaultMessage: 'Slide above to adjust the complexity',
      id: 'v2.quiz.text.complexity_slider',
    },
    qds8006QuestionHeaderText: {
      defaultMessage: 'Almost done!',
      id: 'v2.halfway.text.title',
    },
    qds8006QuestionLastQuestions: {
      defaultMessage: 'Last { noOfAnswersLeftString } Questions',
      id: 'v2.halfway.text.questions_left',
    },
    qds8010SliderText: {
      defaultMessage: 'Slide above to adjust',
      id: 'v2.quiz.text.slide_above',
    },
    qds8011AnswerSectionHeaderText: {
      defaultMessage: 'Slide Below',
      id: 'v2.quiz.text.slide_below',
    },
    skipQuizAnswer: { defaultMessage: 'None of the above', id: 'v2.quiz.cta.none_of_the_above' },
    toBeDiscontinuedProducts: {
      defaultMessage: 'Products to be discontinued',
      id: 'v2.quiz.text.to_be_discontinued',
    },
  }),
  result: defineMessages({
    characterHeader: {
      defaultMessage: 'Your Taste Character',
      id: 'v2.result.text.character',
    },
    characterHeaderNoCharacterText: {
      defaultMessage: 'Based on your taste profile, these are your recommended products',
      id: 'v2.result.text.title',
    },
    headerShortRecommenderCaption: {
      defaultMessage:
        'Select one or more products you would like to try among the suggestions and ask them at the counter.',
      id: 'v2.result.text.caption_short_recommender',
    },
    headerShortRecommenderTitle: {
      defaultMessage: 'We think you might like these flavours!',
      id: 'v2.result.text.title_short_recommender',
    },
    leviaBannerCaptionOne: {
      defaultMessage: '*87% agreement amongst 302 adult consumers in Greece, 2022',
      id: 'v2.result.banner.levia_caption_one',
    },
    leviaBannerCaptionTwo: {
      defaultMessage: '**78% agreement amongst 302 adult consumers in Greece, 2022',
      id: 'v2.result.banner.levia_caption_two',
    },
    leviaBannerTextOne: {
      defaultMessage: 'Discover a world of flavour.​',
      id: 'v2.result.banner.levia_title_one',
    },
    leviaBannerTextTwo: {
      defaultMessage: 'With pleasant smell* & fresh aftertaste**',
      id: 'v2.result.banner.levia_title_two',
    },
    mentholDisclaimer: {
      defaultMessage:
        ' *This product contains menthol. Flavourings should not be confused with the additives contained in tobacco sticks. Find out more: http://www.philipmorris.ch .',
      id: 'v2.result.text.menthol_disclaimer',
    },
    resultLongDisclaimer: {
      defaultMessage:
        'IQOS ILUMA™ is designed to be used only with TEREA™ sticks. Do not use IQOS ILUMA™ and TEREA™ sticks with previous IQOS™ generations, as this may cause damage to your device. Do not ingest or disassemble TEREA™ sticks. This product contains a sharp metal part which can cause serious injury if swallowed. Keep out of reach of children. More information on www.iqos.com.',
      id: 'v2.result.text.disclaimer',
    },
    technicalCaptionText1: {
      defaultMessage: 'You may appreciate this flavour',
      id: 'v2.product.text.technical_caption_two',
    },
    technicalCaptionText2: {
      defaultMessage: 'Your current choice, made better',
      id: 'v2.product.text.technical_caption_one',
    },
    technicalCaptionText3: {
      defaultMessage: `Similar user's favorite`,
      id: 'v2.product.text.technical_caption_three',
    },
    technicalCaptionText4: {
      defaultMessage: 'A top seller',
      id: 'v2.product.text.technical_caption_four',
    },
    technicalCaptionText5: {
      defaultMessage: 'A trending choice',
      id: 'v2.product.text.technical_caption_five',
    },
    tereaBannerText: {
      defaultMessage: 'Discover your recommended TEREA',
      id: 'v2.result.banner.terea',
    },
    veevBannerText: {
      defaultMessage: 'Explore VEEV vaping for a much flavourful experience',
      id: 'v2.result.banner.veev',
    },
  }),
};
