import { defineMessages } from '@formatjs/intl';

export const localeCommon = {
  accessKeyGenerator: defineMessages({
    copyToClipboard: {
      defaultMessage: 'Copy to clipboard',
      id: 'common.link_gen.cta.copy_to_clipboard',
    },
    expirationPeriod: {
      defaultMessage: 'expiration period',
      id: 'common.link_gen.text.expiration_period',
    },
    fromTheMoment: {
      defaultMessage: 'From the moment the link is generated',
      id: 'common.link_gen.text.valid_from',
    },
    generateLink: {
      defaultMessage: 'generate link',
      id: 'common.link_gen.cta.generate',
    },
    header: {
      defaultMessage: 'Links generator',
      id: 'common.link_gen.text.title',
    },
    hours: { defaultMessage: 'Hours', id: 'common.link_gen.text.hours' },
    month: { defaultMessage: 'month', id: 'common.link_gen.text.month' },
    months: { defaultMessage: 'months', id: 'common.link_gen.text.months' },
    retries: {
      defaultMessage: 'Number of uses',
      id: 'common.link_gen.text.retries',
    },
    week: { defaultMessage: 'week', id: 'common.link_gen.text.week' },
    weeks: { defaultMessage: 'weeks', id: 'common.link_gen.text.weeks' },
    year: { defaultMessage: 'year', id: 'common.link_gen.text.year' },
  }),
  ageGate: defineMessages({
    accessBlocked: {
      defaultMessage: 'You must be { ageLimit } or over to access materials on this site.',
      id: 'common.age_gate.text.access_denied',
    },
    april: { defaultMessage: 'April', id: 'common.age_gate.input.april' },
    august: { defaultMessage: 'August', id: 'common.age_gate.input.august' },
    confirmBtn: {
      defaultMessage: 'Confirm',
      id: 'common.age_gate.cta.confirm',
    },
    copyright: {
      defaultMessage: 'Copyright 2020 - All rights reserved',
      id: 'common.age_gate.text.copyright',
    },
    december: { defaultMessage: 'December', id: 'common.age_gate.input.december' },
    february: { defaultMessage: 'February', id: 'common.age_gate.input.february' },
    january: { defaultMessage: 'January', id: 'common.age_gate.input.january' },
    july: { defaultMessage: 'July', id: 'common.age_gate.input.july' },
    june: { defaultMessage: 'June', id: 'common.age_gate.input.june' },
    march: { defaultMessage: 'March', id: 'common.age_gate.input.march' },
    may: { defaultMessage: 'May', id: 'common.age_gate.input.may' },
    month: { defaultMessage: 'Month', id: 'common.age_gate.text.month' },
    november: { defaultMessage: 'November', id: 'common.age_gate.input.november' },
    october: { defaultMessage: 'October', id: 'common.age_gate.input.october' },
    september: { defaultMessage: 'September', id: 'common.age_gate.input.september' },
    support: {
      defaultMessage: 'Customer Support Center - LiveChat on iqos.ro, daily from 8:00 to 22:00',
      id: 'common.age_gate.text.customer_support',
    },
    titleOne: {
      defaultMessage:
        'Are you { ageLimit } years old or older and a smoker or user of other products containing nicotine?',
      id: 'common.age_gate.text.disclaimer',
    },
    titleTwo: {
      defaultMessage: 'Insert your date of birth',
      id: 'common.age_gate.text.instructions',
    },
    year: { defaultMessage: 'Year', id: 'common.age_gate.text.year' },
  }),
  deactivated: defineMessages({
    disableRetailerPageText1: {
      defaultMessage: 'The page you are trying to reach is no longer active.',
      id: 'common.deactivated.text.title',
    },
    disableRetailerPageText2: {
      defaultMessage: "To Access the Flavour Advisor tool, please go to your country's IQOS website.",
      id: 'common.deactivated.text.subtitle',
    },
  }),
  error410Page: defineMessages({
    deactivatedText: {
      defaultMessage:
        'The link you are trying to reach has been deactivated. If you would like to use the Flavor Advisor tool; or for more information on how to proceed, please contact your market representative.',
      id: 'common.error410Page.text.title',
    },
  }),
  geoBlock: defineMessages({
    compliancePartOne: {
      defaultMessage: 'Please note this website is intended for another',
      id: 'common.geoblock.text.compliance_1',
    },
    compliancePartThree: {
      defaultMessage: 'legal requirements, you are not allowed to access it.',
      id: 'common.geoblock.text.compliance_3',
    },
    compliancePartTwo: {
      defaultMessage: 'Country. In order to ensure compliance with local',
      id: 'common.geoblock.text.compliance_2',
    },
    courtesy: {
      defaultMessage: 'We are sorry.',
      id: 'common.geoblock.text.courtesy',
    },
  }),
  healthWarning: defineMessages({
    iqos3HealthWarningFooterToIsraelWarning: {
      defaultMessage: 'WARNING:',
      id: 'common.addon.text.health_warning_title_israel',
    },
    iqos3HealthWarningFooterToIsraelWarningText: {
      defaultMessage: 'Medical researches have determined that cigarettes cause addictions',
      id: 'common.addon.text.health_warning_israel',
    },
    retailerPrepageAgreeBtnText: {
      defaultMessage: 'Agree',
      id: 'common.prepage.cta.agree',
    },
    retailerPrepageHealthWarning: {
      defaultMessage: `Smoking by persons under twenty years of age is prohibited by law. ||Adverse effect on the health of surrounding people cannot be denied for smoke (vapor) from Heated Tobacco Products. Smoking is prohibited in non-smoking areas under the Health Promotion Act. ||Adverse effect on your health cannot be denied for smoke (vapor) from Heated Tobacco Products which includes carcinogen and addictive nicotine.`,
      id: 'common.prepage.text.health_warning',
    },
  }),
  remoteAccessBlock: defineMessages({
    courtesy: {
      defaultMessage: 'We are sorry.',
      id: 'common.rgt.text.courtesy',
    },
    linkExpired: {
      defaultMessage:
        'The link to the flavor advisor experience you tried to access has expired. Please contact IQOS customer care to request a new link.',
      id: 'common.rgt.text.link_expired',
    },
    openIntegration: {
      defaultMessage:
        'The expiration time for the page has passed.Please reload the experience using the "start over" button above to refresh.',
      id: 'common.rgt.text.link_expired_oi',
    },
  }),
  startPage: defineMessages({
    findYourWayTo: {
      defaultMessage: 'Find your way to',
      id: 'common.start.text.title_part_one',
    },
    flavours: {
      defaultMessage: 'flavours.',
      id: 'common.start.text.title_part_two',
    },
    privacyLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'common.start.text.privacy_link',
    },
    privacyText: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'common.start.text.privacy',
    },
    selectorDescriptionText: {
      defaultMessage: 'Follow the Flavour Guide for IQOS and find new flavours crafted for your taste.',
      id: 'common.start.text.subtitle',
    },
    selectorDisclaimerText: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, to make further recommendations on flavours. It does not imply that any products mentioned in the quiz are linked to the product flavours.',
      id: 'common.start.text.disclaimer',
    },
  }),
};
