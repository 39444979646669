import { createSelector } from 'reselect';

import { IRetailerCharactersSlice } from '@lib/core/characters/slices';
import { IParsedCharacter, parseCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';

/**
 * @returns the characters slice state
 */
export const selectCharactersState = (state): IRetailerCharactersSlice => state.characters;

/**
 * @returns all characters array
 */
export const selectCharacters = createSelector([selectCharactersState], charactersState =>
  charactersState.data.map(character => parseCharacter({ character })),
);

/**
 * @returns all characters as JSON with identifiers as keys
 */
export const selectCharactersByIdentifiers = createSelector([selectCharacters], characters => {
  const charactersByIdentifiers: Record<string, IParsedCharacter> = {};
  characters.forEach(character => {
    charactersByIdentifiers[character.characterId] = character;
  });
  return charactersByIdentifiers;
});

/**
 * @returns loading state for characters
 */
export const selectIsCharactersLoading = createSelector(
  [selectCharactersState],
  characters => characters.isLoading || false,
);
/**
 * @returns characters by product category
 */
export const selectCharactersByProductCategory = createSelector([selectCharacters], charactersArray => {
  const characters: Record<string, IParsedCharacter[]> = {};
  charactersArray.forEach(characterData => {
    const {
      characterProductCategory: { name: pc = '' },
    } = characterData;
    if (characters[pc]) {
      characters[pc].push(characterData);
    } else {
      characters[pc] = [characterData];
    }
  });
  return characters || {};
});

/**
 * @returns characters by character type
 */
export const selectCharactersByCharacterType = createSelector(
  [selectCharactersByProductCategory],
  charactersByProductCategory => {
    const charactersByCharacterType: Record<TProductCategory, Record<string, IParsedCharacter[]>> = {};
    Object.keys(charactersByProductCategory).forEach(productCategory => {
      const characterForThisProductCategory = charactersByProductCategory[productCategory];

      if (!charactersByCharacterType[productCategory]) {
        charactersByCharacterType[productCategory] = {};
      }

      characterForThisProductCategory.forEach(character => {
        const { characterType: { slug: characterTypeSlug = '' } = {} } = character;

        if (charactersByCharacterType[productCategory][characterTypeSlug]) {
          charactersByCharacterType[productCategory][characterTypeSlug].push(character);
        } else {
          charactersByCharacterType[productCategory][characterTypeSlug] = [character];
        }
      });
    });
    return charactersByCharacterType;
  },
);
