import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import ArrowTop from '@app/pmi/src/assets/media/crafted/arrow-top.gif';
import { ReactComponent as BackButton } from '@app/pmi/src/assets/media/crafted/Back.svg';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductInstance } from '@lib/core/products/types';
import { selectUserQuizId } from '@lib/core/quizzes/selectors';
import { actionPatchUserQuizMetadata, actionResetUserQuiz } from '@lib/core/quizzes/slices';
import { actionPostNewUserQuiz, actionResetQuizView } from '@lib/core/quizzes/slices/quizView';
import { TQuizData } from '@lib/core/quizzes/types';
import { localeCrafted } from '@lib/tools/locale/source/pmi/crafted';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useEqualElementsHeightV2 } from '@lib/tools/views/hooks';
import { useTypedDispatch as useDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import Gears from '@components/pmi/src/botanicals/organisms/Gears/Gears';
import ProductCarousel from '@components/pmi/src/botanicals/organisms/ProductCarousel/ProductCarousel';

interface Props {
  quizData: TQuizData;
  questionImage: string;
  productLogo: string;
  gprls: TProductInstance[];
  goBack: () => void;
  handleConfirmAnswers: (answerId: string | string[]) => void;
  handleProductCharacter?: (character: IParsedCharacter) => void;
  handleGoToResult: () => void;
}

function QuizPage({
  productLogo,
  goBack,
  handleConfirmAnswers,
  questionImage,
  quizData,
  gprls,
  handleGoToResult,
}: Props) {
  const dispatch = useDispatch();
  useEqualElementsHeightV2(['ta-botanicals-quiz-page-header-select-part-title']);

  const isDesktopResolution = useMediaQuery({ minWidth: 1025 });
  const [selectedAnswers, setSelectedAnswers] = useState({ 1: '', 2: '', 3: '' });
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const [isGearsVisible, setIsGearsVisible] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(1);
  const prevUserQuizId = useSelector(selectUserQuizId);
  const selectedAnswersValues = Object.values(selectedAnswers);
  const isSubmitButtonEnabled = selectedAnswersValues.filter(value => !!value).length === 3;

  useEffect(() => {
    if (isGearsVisible) {
      const payload = {
        metadata: {
          previous_user_test_id: prevUserQuizId,
        },
      };
      dispatch(actionPatchUserQuizMetadata(payload));
      dispatch(actionResetUserQuiz());
      dispatch(actionPostNewUserQuiz());
    } else {
      dispatch(actionResetQuizView());
    }
  }, [isGearsVisible]);

  useEffect(() => {
    if (isCarouselVisible) {
      const recommendedProducts = [];
      gprls.forEach(gprl => recommendedProducts.push(gprl.product.identifier));
      const payload = {
        metadata: {
          result_recommended_products: recommendedProducts,
        },
      };
      dispatch(actionPatchUserQuizMetadata(payload));
    }
  }, [isCarouselVisible]);

  const handleClick = () => {
    handleConfirmAnswers(selectedAnswersValues);
    setIsCarouselVisible(true);
    setIsGearsVisible(false);
  };

  const handleWheelClick = () => {
    if (isCarouselVisible) {
      setIsCarouselVisible(false);
      setIsGearsVisible(true);
    }
  };

  const handleSendResultMetadata = () => {
    const selectedProductId = gprls[activeCardIndex].product.identifier;
    const payload = {
      metadata: {
        product_details: selectedProductId,
      },
    };
    dispatch(actionPatchUserQuizMetadata(payload));
  };

  return (
    <div
      className="ta-botanicals-quiz-page"
      style={{
        background: `linear-gradient(0deg, rgba(52, 48, 61, 0.50) 0%, rgba(52, 48, 61, 0.50) 100%),url(${questionImage})  center/cover no-repeat`,
      }}
    >
      <div className="ta-botanicals-quiz-page-overlay" />
      <button className="ta-botanicals-quiz-page-back-button" onClick={goBack}>
        <BackButton className="ta-botanicals-quiz-page-back-button-icon" />
      </button>

      <img
        alt="Logo"
        src={productLogo}
        className={cn('ta-botanicals-quiz-page-logo', {
          'ta-botanicals-quiz-page-logo-hide': isCarouselVisible,
        })}
      />

      <div className="ta-botanicals-quiz-page-header">
        {!isCarouselVisible ? (
          <div
            className={cn('ta-botanicals-quiz-page-header-select-part', {
              'ta-botanicals-quiz-page-header-select-part-transform': !isCarouselVisible,
            })}
          >
            <div className="ta-botanicals-quiz-page-header-select-part-title">
              <div
                className={cn('ta-botanicals-quiz-page-header-select-part-title-text', {
                  'ta-botanicals-quiz-page-header-select-part-title-text-transform': !isSubmitButtonEnabled,
                })}
              >
                <LocaleFragment message={localeCrafted.quiz.title} />
              </div>
              <div
                className={cn('ta-botanicals-quiz-page-header-select-part-title-text', {
                  'ta-botanicals-quiz-page-header-select-part-title-text-transform': isSubmitButtonEnabled,
                })}
              >
                <LocaleFragment message={localeCrafted.quiz.titleSelect} />
              </div>
            </div>
            <button
              className="ta-botanicals-quiz-page-header-select-part-submit-button"
              disabled={!isSubmitButtonEnabled}
              onClick={handleClick}
            >
              <LocaleFragment message={localeCrafted.quiz.submit} />
            </button>
          </div>
        ) : (
          <div
            className={cn('ta-botanicals-quiz-page-header-result-part', {
              'ta-botanicals-quiz-page-header-result-part-transform': isCarouselVisible,
            })}
          >
            <div className="ta-botanicals-quiz-page-header-result-part-title">
              <LocaleFragment message={localeCrafted.quiz.resultTitle} />
            </div>

            <ProductCarousel
              activeCardIndex={activeCardIndex}
              gprls={gprls}
              handleGoToResult={handleGoToResult}
              handleSendResultMetadata={handleSendResultMetadata}
              setActiveCardIndex={setActiveCardIndex}
            />

            <button
              className="ta-botanicals-quiz-page-header-result-part-flavour-button"
              onClick={() => {
                handleGoToResult();
                handleSendResultMetadata();
              }}
            >
              <LocaleFragment message={localeCrafted.quiz.ctaFlavour} />
            </button>
          </div>
        )}
      </div>
      <div className="ta-botanicals-quiz-page-gear">
        {isDesktopResolution && !isCarouselVisible ? (
          <div className="ta-botanicals-quiz-page-gear-instructions">
            <LocaleFragment message={localeCrafted.quiz.instructions} />
          </div>
        ) : null}
        {isCarouselVisible && (
          <span role="button" tabIndex={0} onClick={handleWheelClick} onKeyUp={handleWheelClick}>
            <img alt="arrow" className="ta-botanicals-quiz-page-gear-icon" src={ArrowTop} />
          </span>
        )}
        <div
          role="button"
          tabIndex={0}
          className={cn('ta-botanicals-quiz-page-gear-wrapper', {
            'ta-botanicals-quiz-page-gear-wrapper-transform': isCarouselVisible,
          })}
          onClick={handleWheelClick}
          onKeyUp={handleWheelClick}
        >
          <Gears
            isResetResult={isGearsVisible}
            isShowArrows={!isCarouselVisible}
            quizData={quizData}
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
          />
        </div>
      </div>
    </div>
  );
}

export default QuizPage;
