import { getRequestCommonParameters } from '@lib/core/service/requests/api';
import { selectServiceInstanceRetailerSlug } from '@lib/core/service/selectors/serviceInstance';
import { store } from '@lib/core/service/store';
/**
 * Get list of quizzes
 *
 * @param {string} retailerSlug
 * @returns {string}
 */
export const apiUrlGetQuizzes = () => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug) return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/`;
};

/**
 * Get quiz data
 *
 * @param {string} retailerSlug
 * @param {string} quizSlug
 * @returns {string}
 */
export const apiUrlGetQuiz = (quizSlug: string) => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug) return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/${quizSlug}`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${quizSlug}/`;
};

export const apiUrlGetCsv = (productCategory: string) => {
  return `https://media-staging.taste-advisor-vh.com/media/scores/${productCategory}.csv`;
};

/**
 * Create new user_test
 *
 * @param {string} retailerSlug
 * @param {string} quizSlug
 * @returns {string}
 */
export const apiUrlPostUserQuiz = (quizSlug: string) => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug) return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/${quizSlug}/user-tests/`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${quizSlug}/user-tests/`;
};

/**
 * Submit an answer
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} userQuizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizAnswer = (userQuizSlug: string, userQuizId: string) => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug)
    return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/${userQuizSlug}/user-tests/${userQuizId}/record-new-answer/`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${userQuizId}/record-new-answer/`;
};

/**
 * * Flags the user_test as `completed` to get the results
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} quizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizComplete = (userQuizSlug: string, quizId: string) => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug) return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/`;
};

/**
 * Associates a user to an anon userQuiz
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} quizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizAssociateUser = (userQuizSlug: string, quizId: string) => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug)
    return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/link-user/`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/link-user/`;
};

/**
 * Populate metadata field
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} quizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizMetadata = (userQuizSlug: string, quizId: string) => {
  const state = store.getState();
  const dutyFreeRetailerSlug = selectServiceInstanceRetailerSlug(state);

  if (dutyFreeRetailerSlug)
    return `/tests/v2/${dutyFreeRetailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/set-metadata/`;

  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/set-metadata/`;
};
