import cn from 'classnames';

import { TProductInstance } from '@lib/core/products/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { showProductWithCoolingEssence } from '@lib/tools/shared/pmi/products';
import { isProductLevia, isProductTagNicotineLevel } from '@lib/tools/shared/pmi/products/filters';
import { HEETS, TEREA } from '@lib/tools/shared/pmi/retailers/consts';
import { useHeight } from '@lib/tools/views/hooks';

import ContinueBtn from '@components/pmi/src/atoms/CTA/Button';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import QuestionHeader from '@components/pmi/src/organisms/Header/Header';
import HealthWarning from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import LeviaBanner from '@components/pmi/src/organisms/Results/Banner/Levia/LeviaBanner';
import MentholDisclaimer from '@components/pmi/src/organisms/Results/MentholDisclaimer/MentholDisclaimer';
import RecommendedProduct from '@components/pmi/src/organisms/Results/RecommendedProduct/RecommendedProduct';
import ResultLongDisclaimer from '@components/pmi/src/organisms/Results/ResultLongDisclaimer/ResultLongDisclaimer';

interface Props {
  continueFlavourAnalysis: () => void;
  isHeetsBackgroundEnabled: boolean;
  isLoading: boolean;
  bestMatchPageBackgroundImage: string;
  gprl: TProductInstance;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isAddToCartAddon: boolean;
  isEnableBuyEcommerceAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTechnicalCaptionEnable: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  selectedProductCategory: string;
  isVeev?: boolean;
  isBackgroundEnabled: boolean;
  isEnableLeviaBannerAddon: boolean;
  isRestrictedResultPageAddon: boolean;
  currentPrimaryColor: string;
  noOfAnswersLeft: number;
  minQuantity: number;
  maxQuantity: number;
  isShowHealthWarningFooter: boolean;
  isMentholDisclaimerAddon: boolean;
  isResultLongDisclaimerAddon: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
  isRestrictedRecommendationAddon: boolean;
}

const BestMatchPage = ({
  isHeetsBackgroundEnabled,
  isLoading,
  bestMatchPageBackgroundImage,
  gprl,
  isAddToCartAddon,
  isAromaNoteSecondaryAddon,
  isAromaNotesDisabledAddon,
  isEnableBuyEcommerceAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isTechnicalCaptionEnable,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  selectedProductCategory,
  continueFlavourAnalysis,
  isVeev,
  isBackgroundEnabled,
  currentPrimaryColor,
  isRestrictedResultPageAddon,
  noOfAnswersLeft,
  maxQuantity,
  minQuantity,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isShowHealthWarningFooter,
  isMentholDisclaimerAddon,
  isHealthWarningFooterToIsraelAddon,
  isResultLongDisclaimerAddon,
  isEnableLeviaBannerAddon,
  isRestrictedRecommendationAddon,
}: Props) => {
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const isProductWithCoolingEssencePresent = showProductWithCoolingEssence(gprl) && !isEssenceCoolingDisabledAddon;
  const isLeviaBannerAvailable = isProductLevia(gprl);

  let bestMatchPageBackground = '';

  if (isRestrictedResultPageAddon && selectedProductCategory === TEREA) {
    bestMatchPageBackground = '#51BAB0';
  } else if (isRestrictedResultPageAddon && selectedProductCategory === HEETS) {
    bestMatchPageBackground = '#f6f4f0';
  } else if (isVeev) {
    bestMatchPageBackground = 'linear-gradient(90deg, #21164F 0%, #265B7D 100%)';
  } else {
    bestMatchPageBackground = isBackgroundEnabled
      ? `url(${bestMatchPageBackgroundImage}) center/cover no-repeat `
      : '#f6f4f0';
  }

  const bestMatchPageStyles = {
    background: bestMatchPageBackground,
    paddingBottom: paddingForFooter,
  };

  const noOfAnswersLeftString = noOfAnswersLeft.toString();

  // ! Use current depth instead of this static text
  const questionsLeft = (
    <LocaleFragment message={localeV2.bestMatch.bestMatchQuestionsLeftText} options={{ noOfAnswersLeftString }} />
  );

  return (
    <div
      className={`ta-v2-best-match-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={bestMatchPageStyles}
    >
      <QuestionHeader
        isHideProgressBar
        caption=""
        className="ta-v2-best-match-page-question-header"
        currentPrimaryColor={currentPrimaryColor}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isLoading={isLoading}
        isShowBackButton={false}
        title={localeV2.bestMatch.bestMatchHeaderText}
      />
      <div className="ta-v2-best-match-page-container">
        <RecommendedProduct
          isFusePage
          gprl={gprl}
          isAddToCartAddon={isAddToCartAddon}
          isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
          isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
          isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
          isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
          isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
          isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
          isMentholDisclaimerAddon={isMentholDisclaimerAddon}
          isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
          isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
          isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
          isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
          isTechnicalCaptionEnable={isTechnicalCaptionEnable}
          isTereaHideEssencesAddon={isTereaHideEssencesAddon}
          isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
          isVeev={isVeev}
          maxQuantity={maxQuantity}
          minQuantity={minQuantity}
          nicotineLevelData={gprl.retailer_product_location_tags?.filter(isProductTagNicotineLevel)}
          selectedProductCategory={selectedProductCategory}
        />
        <span
          className={cn('ta-v2-best-match-page-unsure-text', {
            isVeev,
          })}
        >
          <LocaleFragment message={localeV2.bestMatch.bestMatchNotSureText} />
        </span>
        <ContinueBtn
          btnText={<LocaleFragment message={localeV2.bestMatch.bestMatchBtnText} />}
          className="margin-top-24px"
          isDark={false}
          onBtnClick={continueFlavourAnalysis}
        />
        <span
          className={cn('font-ta-v2-global-caption-small ta-v2-best-match-page-small-text', {
            isVeev,
          })}
        >
          {questionsLeft}
        </span>
      </div>
      {isMentholDisclaimerAddon && isProductWithCoolingEssencePresent && <MentholDisclaimer />}
      {isResultLongDisclaimerAddon && <ResultLongDisclaimer />}
      {isEnableLeviaBannerAddon && isLeviaBannerAvailable && <LeviaBanner />}
      {isShowHealthWarningFooter && (
        <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
          <HealthWarning />
        </div>
      )}
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default BestMatchPage;
