import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

const selectComments = (state: RootState) => state?.comments;

const selectCommentsData = createSelector([selectComments], comments => comments.data);

const selectIsCommentsLoading = createSelector([selectComments], comments => comments.isLoading);

const selectIsCommentUpdating = createSelector([selectComments], comments => comments.isCommentUpdating);

const selectIsCommentCreating = createSelector([selectComments], comments => comments.isCommentCreating);

export { selectCommentsData, selectIsCommentCreating, selectIsCommentsLoading, selectIsCommentUpdating };
