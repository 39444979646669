import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TProductFeedback, TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { ILocation } from '@lib/core/users/types';

export const isProductInstanceInWishlistFilter = (
  wishlistProductInstanceIds: string[] = [],
  productInstanceData: TProductInstance,
) => wishlistProductInstanceIds?.includes(productInstanceData?.product?.identifier);

export const isLocationInWishlistFilter = (locationData: ILocation[] = [], locationId: string) =>
  locationData?.some(
    data => data?.retailer_location?.identifier === locationId || data?.retailerLocation?.identifier === locationId,
  );

export const feedbackFilter = (
  feedbackData: TProductFeedback[],
  productInstanceData: TProductInstance,
): TProductFeedbackValue =>
  feedbackData.find(feedback => feedback?.product?.identifier === productInstanceData?.identifier)?.feedback;

export const isFeedbackByProductCategory = (feedback: TProductFeedback, productCategory: TProductCategory): boolean =>
  feedback.product.product.category === productCategory;
