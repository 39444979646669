import { useSelector } from 'react-redux';

import {
  selectIsServiceInstanceFeatureEnabled,
  selectIsServiceInstanceRetailerDataLoading,
  selectServiceInstanceProductCategory,
  selectServiceInstanceRetailerLocationId,
  selectServiceInstanceRetailerLocationSlug,
  selectServiceInstanceRetailerLogo,
  selectServiceInstanceRetailerSlug,
  selectServiceInstanceRetailerType,
} from '@lib/core/service/selectors/serviceInstance';

export const useServiceInstance = () => ({
  isServiceInstanceFeatureEnabled: useSelector(selectIsServiceInstanceFeatureEnabled),
  isServiceInstanceRetailerDataLoading: useSelector(selectIsServiceInstanceRetailerDataLoading),
  serviceInstanceProductCategory: useSelector(selectServiceInstanceProductCategory),
  serviceInstanceRetailerLocationId: useSelector(selectServiceInstanceRetailerLocationId),
  serviceInstanceRetailerLocationSlug: useSelector(selectServiceInstanceRetailerLocationSlug),
  serviceInstanceRetailerLogo: useSelector(selectServiceInstanceRetailerLogo),
  serviceInstanceRetailerSlug: useSelector(selectServiceInstanceRetailerSlug),
  serviceInstanceType: useSelector(selectServiceInstanceRetailerType),
});
