import { FC, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import ButtonScrollDown from '@components/pmi/src/atoms/CTA/ButtonScrollDown';
import SkipButton from '@components/pmi/src/atoms/CTA/Navigator';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';
import QuestionHeader from '@components/pmi/src/organisms/Header/Header';
import HealthWarning from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import AnswerSection from '@components/pmi/src/organisms/Quiz/QDS8002/AnswerSection/AnswerSection';
import QuizFooter from '@components/pmi/src/organisms/Quiz/QuizFooter/QuizFooter';

interface Props {
  answers: TQuizAnswerData[];
  questionTitle: string;
  questionCaption: string;
  progressPercent: number;
  handleConfirmAnswer: (answerId: string | string[], callback: () => void) => void;
  goBack: () => void;
  questionImage: string;
  choicesQuantity: {
    maxChoices: number;
    minChoices: number;
  };
  isShowBackButton: boolean;
  isLoading: boolean;
  isHealthWarningQuizAddon: boolean;
  isHeetsBackgroundEnabled: boolean;
  isLight?: boolean;
  currentPrimaryColor: string;
  isShowRestrictedBackground: boolean;
  isIgnoreProductSeparationAddon: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

const QuizPage: FC<Props> = ({
  isLight = false,
  currentPrimaryColor,
  questionTitle,
  questionCaption,
  answers,
  progressPercent,
  handleConfirmAnswer,
  goBack,
  questionImage,
  choicesQuantity,
  isShowBackButton,

  isLoading,
  isHealthWarningQuizAddon,
  isHeetsBackgroundEnabled,
  isShowRestrictedBackground,
  isIgnoreProductSeparationAddon,
  isHealthWarningFooterToIsraelAddon,
}) => {
  const bottomRef = useRef(null);
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const minChoicesQuantity = choicesQuantity?.minChoices;
  const maxAnswersChoices = choicesQuantity?.maxChoices;
  const answersWithImage = answers.slice(0, -1);
  const noneOfTheAboveAnswer = answers.slice(-1);
  const noneOfTheAboveAnswerId = Object.keys(noneOfTheAboveAnswer[0])[0];
  const skipButtonText = noneOfTheAboveAnswer[0]?.[noneOfTheAboveAnswerId]?.text || '';
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);

  const [answersArray, setAnswersArray] = useState<string[]>([]);
  const handleClick = () => {
    handleConfirmAnswer(answersArray, () => {
      setAnswersArray([]);
    });
  };
  const handleClickNoneOfTheAbove = () => {
    handleConfirmAnswer(noneOfTheAboveAnswerId, () => {
      setAnswersArray([]);
    });
  };

  const isNextButtonEnabled = answersArray.length >= minChoicesQuantity && maxAnswersChoices >= 2;
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const { isLayoutRightToLeft } = useApp();

  return (
    <div
      ref={bottomRef}
      className={`ta-v2-qds8009-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={{
        background: isShowRestrictedBackground ? '#51BAB0' : currentBackground,
        paddingBottom: paddingForFooter,
      }}
    >
      <QuestionHeader
        caption={questionCaption}
        currentPrimaryColor={currentPrimaryColor}
        goBack={goBack}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isLoading={isLoading}
        isShowBackButton={isShowBackButton}
        progressPercent={progressPercent}
        title={questionTitle}
      />
      <div className="ta-v2-qds8009-page-content-part">
        <AnswerSection
          answers={answersWithImage}
          answersArray={answersArray}
          currentPrimaryColor={currentPrimaryColor}
          handleConfirmAnswer={handleConfirmAnswer}
          isIgnoreProductSeparationAddon={isIgnoreProductSeparationAddon}
          isLight={isLight}
          maxAnswersChoices={maxAnswersChoices}
          setAnswersArray={setAnswersArray}
        />
        <SkipButton
          btnText={skipButtonText}
          direction="right"
          isDark={!isLight}
          isLayoutRightToLeft={isLayoutRightToLeft}
          onBtnClick={handleClickNoneOfTheAbove}
        />
        {!isMobileResolution && maxAnswersChoices > 1 ? (
          <CTAButton
            btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
            className="ta-v2-cta-btn-margin"
            isDark={isNextButtonEnabled}
            isDisabled={!isNextButtonEnabled}
            onBtnClick={handleClick}
          />
        ) : null}
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        <ButtonScrollDown
          showScrollArrow
          bottomRef={bottomRef}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
        />
        {isMobileResolution && maxAnswersChoices > 1 ? (
          <QuizFooter handleClick={handleClick} isNextButtonEnabled={isNextButtonEnabled} />
        ) : null}
        {isHealthWarningQuizAddon && <HealthWarning />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default QuizPage;
