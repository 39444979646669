import { ICharacterType, IFoodPairing, TCharacter } from '@lib/core/characters/types';
import { TCharacterAttributes } from '@lib/core/characters/types/attributes';
import { TEssence } from '@lib/core/characters/types/essences';

export interface IParsedCharacter {
  characterRelatedEssencesSorted: TEssence[];
  characterCaption: string;
  characterDescription: string;
  characterType: ICharacterType;
  characterId: string;
  characterImage: string;
  characterName: string;
  characterPosition: number;
  characterProductCategory: { name: string };
  characterRedirectUrl: string;
  characterTechnicalCaption: string;
  characterTechnicalDescription: string;
  characterAroma: string;
  characterAttributes?: Partial<TCharacterAttributes>;
  characterEssences?: TEssence[];
  characterFoodPairing?: IFoodPairing;
}

export const parseCharacter = ({ character }: { character: TCharacter }): IParsedCharacter => {
  const characterRelatedEssencesSorted = Array.isArray(character?.essences)
    ? [...character.essences].sort((a, b) => a.essence?.position - b.essence?.position)
    : [];
  return {
    characterRelatedEssencesSorted,
    ...character,
    characterAroma: character?.aroma || '',
    characterAttributes: character?.attributes || {},
    characterCaption: character?.characterCaption || '',
    characterDescription: character?.characterDescription,
    characterEssences: character?.essences || [],
    characterFoodPairing: character?.foodPairing || {
      description: character?.foodPairing?.description || '',
      image: character?.foodPairing?.image || '',
      slug: character?.foodPairing?.slug || '',
      title: character?.foodPairing?.title || '',
    },
    characterId: character?.identifier || '',
    characterImage: character?.image || '',
    characterName: character?.name || '',
    characterPosition: character?.position || 0,
    characterProductCategory: { name: character?.productCategory?.name || '' },
    characterRedirectUrl: character?.redirectUrl || '',
    characterTechnicalCaption: character?.technicalCaption || '',
    characterTechnicalDescription: character?.technicalDescription || '',
    characterType: {
      identifier: character?.characterType?.identifier || '',
      name: character?.characterType?.name || '',
      slug: character?.characterType?.slug || '',
    },
  };
};
