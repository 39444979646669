import { css, styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const LowSpeedWarningContainer = styled(Flexbox)`
  position: absolute;
  z-index: 9999;
  top: 58%;
  background: rgba(42, 90, 111, 0.8);
  width: 343px;
  opacity: 0.8;
  padding: 16px;
  gap: 4px;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      width: 824px;
    `}
`;
