import { createSelector } from 'reselect';

import { selectCharactersByIdentifiers } from '@lib/core/characters/selectors';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { RootState } from '@lib/core/service/types/appStateType';
import { IUserCharacterSlice } from '@lib/core/users/slices/characters';
import RouteUtils from '@lib/tools/routes';

/**
 * @returns the whole user characters state
 */
export const selectUserCharactersState = (state): IUserCharacterSlice => state.users.characters;

/**
 * @returns user characters extended with full character data
 */
export const selectUserCharacters = createSelector(
  [selectUserCharactersState, selectCharactersByIdentifiers],
  (userCharactersState, charactersByIdentifiers) => {
    return userCharactersState?.data?.length
      ? userCharactersState?.data
          .map(({ character }) => charactersByIdentifiers[character?.identifier])
          .filter(character => !!character?.characterId)
      : [];
  },
);

/**
 * @returns array of all user characters Ids
 */
export const selectUserCharactersIds = createSelector([selectUserCharactersState], userCharactersState =>
  userCharactersState?.data.length ? userCharactersState?.data.map(({ character }) => character?.identifier) : [],
);

/**
 * @returns user characters for category
 */
export const selectUserCharactersByProductCategory = createSelector([selectUserCharacters], userCharactersArray => {
  const userCharacters = {};
  userCharactersArray.forEach(characterData => {
    const {
      characterProductCategory: { name: productCategoryName },
    } = characterData;
    if (userCharacters[productCategoryName]) {
      userCharacters[productCategoryName].push(characterData);
    } else {
      userCharacters[productCategoryName] = [characterData];
    }
  });
  return userCharacters || {};
});

/**
 * @returns user characters for service product category array
 */
export const selectUserCharactersForServiceProductCategoryArray = createSelector(
  [selectUserCharacters, (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory()],
  (userCharactersArray, productCategory) =>
    userCharactersArray.filter(character => character.characterProductCategory?.name === productCategory),
);

/**
 * @returns user best matches character for category
 */
export const selectUserBestMatchCharacterByProductCategory = createSelector(
  [selectUserCharactersByProductCategory],
  userCharactersByCategory => {
    const userCharacters: Record<string, IParsedCharacter> = {};
    Object.keys(userCharactersByCategory).forEach(pc => {
      userCharacters[pc] = userCharactersByCategory[pc]?.[userCharactersByCategory[pc].length - 1];
    });
    return userCharacters;
  },
);

/**
 * @returns user best matches characters identifiers as single string (useful for useEffect)
 */
export const selectUserBestMatchCharacterByProductCategoryIdentifiers = createSelector(
  [selectUserBestMatchCharacterByProductCategory],
  userBestMatchCharacterByProductCategory =>
    Object.values(userBestMatchCharacterByProductCategory)
      .map(character => character.characterId)
      .join(','),
);

/**
 * @returns if user has any character (completed at least 1 test)
 */
export const selectIsUserHasAnyCharacter = createSelector(
  [selectUserCharacters],
  userCharacters => !!userCharacters.length,
);

/**
 * @returns if user has character for retailer where we have only 1 product type
 */
export const selectIsUserHasCharacterForCurrentProductCategory = createSelector(
  [
    selectUserBestMatchCharacterByProductCategory,
    (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory(),
  ],
  (userCharacters, productCategory) => {
    return !!userCharacters[productCategory] || false;
  },
);

/**
 * @returns if user characters are loading
 */
export const selectIsCharactersAreLoading = createSelector(
  [selectUserCharactersState],
  userCharactersState => !!userCharactersState.isLoading,
);

/**
 * @returns user characters for current product category
 */
export const selectBestMatchCharacterForServiceProductCategory = createSelector(
  [
    selectUserBestMatchCharacterByProductCategory,
    (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory(),
  ],
  (userBestMatchCharacterByCategory, productCategory) => userBestMatchCharacterByCategory[productCategory] || undefined,
);
