import { Alert, Button, CardActionArea, CardMedia, FormControl, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import dagre from 'dagre';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'reactflow/dist/style.css';
import {
  ConnectionLineType,
  Controls,
  Handle,
  Position,
  ReactFlow,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import { useChangedNodes } from '@lib/core/quizzes/hooks/changedNodes';
import {
  addNewNodeToMatrix,
  arrangeDeletedEdges,
  changeScoreForAlreadyInPath,
  changeScoreForAlreadyInState,
  updateNodeState,
  updateNodeToRemoved,
  updateNodeToRemovedNotInState,
} from '@lib/core/quizzes/slices';
import NewNodeForm from '@lib/tools/devtools/components/NewNodeForm';
import NodeInfoForm from '@lib/tools/devtools/components/NodeInfoForm';
import { themeDevTools } from '@lib/tools/devtools/components/theme';

import '@lib/tools/devtools/panels/sensory/react-flow.scss';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 350;
const nodeHeight = 400;
const MIN_DISTANCE = 350;
let problematicNodes = [];

const getLayoutedElements = (nodes: any, edges: any, direction = 'TB') => {
  try {
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node: any) => {
      dagreGraph.setNode(node.id, { height: nodeHeight, width: nodeWidth });
    });

    edges.forEach((edge: any) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
    dagreGraph.graph().nodesep = 20;
    dagreGraph.graph().ranksep = 40;

    dagre.layout(dagreGraph);
    const whiteEdges = edges.map((edge: any) => ({
      ...edge,
      style: { stroke: 'white' },
    }));

    return {
      edges: whiteEdges,
      nodes: nodes.map(node => {
        const { x, y } = dagreGraph.node(node.id);

        return { ...node, position: { x, y } };
      }),
    };
  } catch (e) {
    console.error(e);
    return {};
  }
};

function CustomNode({ data }) {
  const hasImage = !!(data.image && data.image.trim() !== '');

  let borderProperties = '1px solid #e1ad01';

  if (problematicNodes.includes(data.label)) {
    borderProperties = '10px solid red';
  }
  try {
    return (
      <div style={{ border: borderProperties }}>
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            {hasImage && <CardMedia alt={data.label} component="img" height="140" image={data.image} />}
            <div style={{ backgroundColor: ' #3e363f', padding: '8px' }}>
              <CardContent>
                <Typography gutterBottom color="#73a580" variant="h5">
                  {data.label}
                </Typography>
                <Typography color="#73a580" variant="body2">
                  {data.text}
                </Typography>
              </CardContent>
            </div>
          </CardActionArea>
          <Handle id={`source-${data.label}`} position={Position.Bottom} style={{ background: '#555' }} type="source" />
          <Handle id={`target-${data.label}`} position={Position.Top} style={{ background: '#225' }} type="target" />
        </Card>
      </div>
    );
  } catch (e) {
    console.error(e);
  }
}

function LayoutFlow({
  selectedProductCategory,
  initialNodes,
  initialEdges,
  scoreMatrix,
  firstQuestion,
  lastAnswers,
  getCurrentNodeEdgeInfo,
}) {
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges);
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const { changedNodes } = useChangedNodes();

  const [clickedNode, setClickedNode] = useState({});
  const [clickedNodeScores, setClickedNodeScore] = useState<[]>([]);
  const [clickedNodeText, setClickedNodeText] = useState('');
  const [charactersList, setCharacterList] = useState<[]>([]);
  const [inputValue, setInputValue] = useState('');

  const [showForm, setShowForm] = useState(false);
  const [createNodeOpen, setCreateNodeOpen] = useState(false);
  const [addNodeOpen, setAddNodeOpen] = useState(false);

  const dispatch = useDispatch();
  // close button is clicked, get the last nodes from layout flow

  const defaultViewPort = { x: 0, y: 0, zoom: 0.8 };

  const nodeTypes = useMemo(
    () => ({
      custom: CustomNode,
    }),
    [],
  );

  const handleSubmit = (selectedNode: any, scoresInfo: Record<string, number>, text: string) => {
    // node, char and new score is arrived

    const parentId = selectedNode.parent;
    const nodeId = selectedNode.id;
    if (nodeId.startsWith('QA')) {
      // for the nodes that are not changed previously and its the first time so it's in scoreMatrix
      if (
        !Object.keys(changedNodes).length ||
        !Object.keys(changedNodes[selectedProductCategory]).includes(selectedNode.id)
      ) {
        const foundKey = Object.keys(scoreMatrix.scores).find(key => {
          const answersObj = scoreMatrix.scores[key];
          const answerArray = Object.keys(answersObj);
          return answerArray.includes(nodeId);
        });
        const scoreArray = [...scoreMatrix.scores[foundKey][selectedNode.id]]; // without the change before
        let scoresObjIndex;
        const changedScoreArray = Object.values(scoresInfo);

        Object.keys(scoresInfo).forEach((info, i) => {
          scoresObjIndex = scoreMatrix.characters.indexOf(info);

          scoreArray[scoresObjIndex] = changedScoreArray[i];
        });
        let newText;
        if (!text) {
          newText = selectedNode.text;
        } else {
          newText = text;
        }
        dispatch(changeScoreForAlreadyInPath({ newText, nodeId, parentId, scoreArray, selectedProductCategory }));
      } else {
        // for the nodes that are newly created or changed before

        const scoreArray = [...changedNodes[selectedProductCategory][nodeId].scoresArray];
        let scoresObjIndex;
        const changedScoreArray = Object.values(scoresInfo);
        Object.keys(scoresInfo).forEach((info, i) => {
          scoresObjIndex = scoreMatrix.characters.indexOf(info);
          scoreArray[scoresObjIndex] = changedScoreArray[i];
        });
        let newText;
        if (!text) {
          newText = selectedNode.data.text;
        } else {
          newText = text;
        }
        dispatch(changeScoreForAlreadyInState({ newText, nodeId, scoreArray, selectedProductCategory }));
      }

      if (text) {
        const copyNodes = [...nodes];
        copyNodes.map(currentNode => {
          if (currentNode.id === nodeId) {
            currentNode.data.text = text;
          }
          return currentNode;
        });
        setNodes(copyNodes);
      }
    }
  };

  const CheckIfReadyToBeDownloaded = () => {
    const nodesCopy = [...nodes];

    const problemNodes = [];

    nodesCopy.forEach((node: any) => {
      if (node.id.startsWith('QA')) {
        if (!node.parent || !node.parent.length) {
          problemNodes.push(node.id);
        } else if (!node.children || !node.children.length) {
          if (!lastAnswers.includes(node.id)) {
            problemNodes.push(node.id);
          }
        }
      } else if (node.id !== firstQuestion && (!node.parent || !node.parent.length)) {
        problemNodes.push(node.id);
      } else if (!node.children || !node.children.length) {
        problemNodes.push(node.id);
      }
    });

    problematicNodes = [...problemNodes];

    return !problematicNodes.length; // Return true if it's ready, false otherwise
  };

  const onNodeClick = (node: any) => {
    if (node.id.startsWith('QA')) {
      let scoreList;
      let text;

      if (
        changedNodes[selectedProductCategory] &&
        Object.keys(changedNodes[selectedProductCategory]).includes(node.id)
      ) {
        scoreList = changedNodes[selectedProductCategory][node.id].scoresArray;
        text = changedNodes[selectedProductCategory][node.id].text;
      } else {
        const foundKey = Object.keys(scoreMatrix.scores).find(key => {
          const answersObj = scoreMatrix.scores[key];
          const answerArray = Object.keys(answersObj);
          return answerArray.includes(node.id);
        });

        scoreList = scoreMatrix.scores[foundKey][node.id];
        text = node.text;
      }

      setClickedNode(node);
      const characterList = scoreMatrix.characters; // ["IC22", "IC11"]
      setCharacterList(characterList);
      setShowForm(true);
      setClickedNodeText(text);
      setClickedNodeScore(scoreList);
    }
  };

  const onConnect = useCallback(
    (params: any) => {
      if (params.target === firstQuestion) return;
      if (lastAnswers.includes(params.source)) return;
      const parentObj = nodes.filter(node => node.id === params.source)[0];
      const parentObjCopy: Record<string, any> = JSON.parse(JSON.stringify(parentObj));
      const childObj = nodes.filter(node => node.id === params.target)[0];
      const childObjCopy: Record<string, any> = JSON.parse(JSON.stringify(childObj));

      // preventing answer to answer connection
      if (childObjCopy.id.startsWith('QA') && (childObjCopy.parent || parentObjCopy.id.startsWith('QA'))) {
        return;
      }
      // preventing question to question connection
      if (childObjCopy.id.startsWith('QQ') && parentObjCopy.id.startsWith('QQ')) {
        return;
      }

      // preventing Q1 -> A1 connection when there is A1 -> Q1 connection
      if (parentObjCopy.parent === childObjCopy.id) {
        return;
      }

      if (parentObjCopy.children) {
        parentObjCopy.children.push(params.target);
      } else {
        parentObjCopy.children = [params.target];
      }

      const updatedChildObj = {
        ...childObjCopy,
        parent: params.source,
      };

      const copyOfNodes = [...nodes];
      let text;
      const updatedNodes = copyOfNodes.map((node: any) => {
        if (node.id === params.target) {
          return parentObjCopy;
        }
        if (node.id === params.source) {
          text = node.text;
          return updatedChildObj;
        }
        return node;
      });

      setNodes(updatedNodes);
      const nodeID = params.target;
      const newParentID = params.source;

      setEdges(eds => addEdge({ ...params, animated: true, type: ConnectionLineType.SmoothStep }, eds));
      // dispatch(connectEdgesAlreadyInState({ selectedProductCategory, nodeID, newParentID }));

      if (newParentID.startsWith('QA')) {
        if (
          !Object.keys(changedNodes[selectedProductCategory]).length ||
          !Object.keys(changedNodes[selectedProductCategory]).includes(newParentID)
        ) {
          const foundKey = Object.keys(scoreMatrix.scores).find(key => {
            const answersObj = scoreMatrix.scores[key];
            const answerArray = Object.keys(answersObj);
            return answerArray.includes(newParentID);
          });

          const initializedArray = scoreMatrix.scores[foundKey][newParentID];
          dispatch(
            addNewNodeToMatrix({
              addedChildren: nodeID,
              id: newParentID,
              initializedArray,
              parentID: foundKey,
              selectedProductCategory,
              text,
            }),
          );
        } else {
          dispatch(updateNodeState({ addedChildren: nodeID, id: newParentID, selectedProductCategory }));
        }
      } else if (newParentID.startsWith('QQ')) {
        if (
          !Object.keys(changedNodes[selectedProductCategory]).length ||
          !Object.keys(changedNodes[selectedProductCategory]).includes(newParentID)
        ) {
          dispatch(
            addNewNodeToMatrix({
              addedChildren: nodeID,
              id: newParentID,
              selectedProductCategory,
              text,
            }),
          );
        } else {
          dispatch(updateNodeState({ addedChildren: nodeID, id: newParentID, selectedProductCategory }));
        }
      }
    },
    [nodes, edges],
  );

  const onLayout = useCallback(
    (direction: any) => {
      const { nodes: NodesLayouted, edges: EdgesLayouted } = getLayoutedElements(nodes, edges, direction);

      setNodes([...NodesLayouted]);
      setEdges([...EdgesLayouted]);
    },
    [nodes, edges],
  );

  const onNodesDelete = useCallback(
    (params: any) => {
      const nodesCopy = [...nodes];
      const nodesCopy2 = [...nodes];
      const deletedNode = nodesCopy2.filter(node => node.id === params[0].id)[0];

      const notDeletedNodes = nodesCopy.filter(node => !(node.id === params[0].id));

      const updatedNodes = notDeletedNodes.map((node2: any) => {
        if (
          node2.parent === params[0].id ||
          (node2.parent && Array.isArray(node2.parent) && node2.parent.includes(params[0].id))
        ) {
          if (node2.id.startsWith('QQ')) {
            const parentAnswersArray = [...node2.parent];

            const filteredParentsAnswerArray = parentAnswersArray.filter(parentAnswer => parentAnswer !== params[0].id);

            return { ...node2, parent: filteredParentsAnswerArray };
          }

          if (node2.id.startsWith('QA')) {
            return { ...node2, parent: null };
          }
        }
        if (node2.children && node2.children.includes(params[0].id)) {
          const childrenArray = [...node2.children];
          const filteredChildren = childrenArray.filter((child: any) => !(child === params[0].id));

          return { ...node2, children: filteredChildren };
        }
        return node2;
      });
      const { id } = params[0];
      // const removedChildren = params[0].children;
      // const parent = params[0].parent;
      if (changedNodes && changedNodes[selectedProductCategory] && changedNodes[selectedProductCategory][id])
        dispatch(updateNodeToRemoved({ id, selectedProductCategory }));
      else {
        let scoreArray;
        if (id.startsWith('QA')) {
          const foundKey = Object.keys(scoreMatrix.scores).find(key => {
            const answersObj = scoreMatrix.scores[key];
            const answerArray = Object.keys(answersObj);
            return answerArray.includes(id);
          });
          scoreArray = scoreMatrix.scores[foundKey][id];
        }
        const { text } = deletedNode.data;
        dispatch(updateNodeToRemovedNotInState({ id, scoreArray, selectedProductCategory, text }));
      }

      setShowForm(false);
      setNodes(updatedNodes);
    },
    [nodes, edges],
  );

  const onEdgesDelete = useCallback(
    (params: any) => {
      let leftEdges = [...edges];
      const nodesArray = [...nodes];
      const editedObjects = { alreadyInChangedNodes: {}, notInChangedNodes: {} };
      params.forEach(param => {
        leftEdges = leftEdges.filter(edge => !(edge.source === param.source && edge.target === param.target));
        let text;
        nodesArray.forEach((node: any) => {
          if (node.id === param.target) {
            if (node.id.startsWith('QA')) {
              node.parent = null;
            } else {
              const parentAnswersArray = [...node.parent];
              const filteredParentsAnswerArray = parentAnswersArray.filter(
                parentAnswer => parentAnswer !== param.source,
              );

              node.parent = filteredParentsAnswerArray;
            }
          } else if (node.id === param.source) {
            const tempChildren = node.children.filter(child => child !== param.target);
            node.children = [...tempChildren];
            text = node.text;
            if (param.source.startsWith('QA')) {
              if (
                !Object.keys(changedNodes).length ||
                !Object.keys(changedNodes[selectedProductCategory]).includes(param.source)
              ) {
                if (editedObjects.notInChangedNodes[param.source]) {
                  editedObjects.notInChangedNodes[param.source].removedChildren.push(param.target);
                } else {
                  const foundKey = Object.keys(scoreMatrix.scores).find(key => {
                    const answersObj = scoreMatrix.scores[key];
                    const answerArray = Object.keys(answersObj);
                    return answerArray.includes(param.source);
                  });

                  const initializedArray = scoreMatrix.scores[foundKey][param.source];
                  editedObjects.notInChangedNodes[param.source] = {
                    id: param.source,
                    initializedArray,
                    parentID: foundKey,
                    removedChildren: [param.target],
                    selectedProductCategory,
                    text,
                  };
                }
              } else if (!editedObjects.alreadyInChangedNodes[param.source]) {
                editedObjects.alreadyInChangedNodes[param.source] = {
                  id: param.source,
                  removedChildren: [param.target],
                  selectedProductCategory,
                };
              } else {
                editedObjects.alreadyInChangedNodes[param.source].removedChildren.push(param.target);
              }
            } else if (param.source.startsWith('QQ')) {
              if (
                !Object.keys(changedNodes).length ||
                !Object.keys(changedNodes[selectedProductCategory]).includes(param.source)
              ) {
                if (!editedObjects.notInChangedNodes[param.source]) {
                  editedObjects.notInChangedNodes[param.source] = {
                    id: param.source,
                    removedChildren: [param.target],
                    selectedProductCategory,
                    text,
                  };
                } else {
                  editedObjects.notInChangedNodes[param.source].removedChildren.push(param.target);
                }
              } else if (!editedObjects.alreadyInChangedNodes[param.source]) {
                editedObjects.alreadyInChangedNodes[param.source] = {
                  id: param.source,
                  removedChildren: [param.target],
                  selectedProductCategory,
                };
              } else {
                editedObjects.alreadyInChangedNodes[param.source].removedChildren.push(param.target);
              }
            }
          }
        });
      });

      dispatch(arrangeDeletedEdges(editedObjects));
      setNodes(nodesArray);
      setEdges([...leftEdges]);

      // removing edge QA2 -> QQ8
    },

    [nodes, edges],
  );

  const getClosestEdge = useCallback(node => {
    const closestNode = nodes.reduce(
      (res, n) => {
        if (n.id !== node.id) {
          const dx = n.position.x - node.position.x;

          const dy = n.position.y - node.position.y;
          const d = Math.sqrt(dx * dx + dy * dy);

          if (d < res.distance && d < MIN_DISTANCE) {
            res.distance = d;
            res.node = n;
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null,
      },
    );

    if (!closestNode.node) {
      return null;
    }

    const closeNodeIsSource = closestNode.node.position.y < node.position.y;

    return {
      id: `${node.id}-${closestNode.node.id}`,
      source: closeNodeIsSource ? closestNode.node.id : node.id,
      target: closeNodeIsSource ? node.id : closestNode.node.id,
      type: 'smoothstep',
    };
  }, []);

  const onNodeDrag = useCallback(
    (_, node) => {
      const closeEdge: any = getClosestEdge(node);

      if (closeEdge) {
        const copyNodes = [...nodes];

        const childObj: any = copyNodes.filter((currentNode2: any) => currentNode2.id === closeEdge.target)[0];

        setEdges(es => {
          const nextEdges = es.filter(e => e.className !== 'temp');

          if (
            closeEdge &&
            closeEdge.target !== firstQuestion &&
            !lastAnswers.includes(closeEdge.source) &&
            !(closeEdge.target.startsWith('QA') && (closeEdge.source.startsWith('QA') || childObj.parent)) &&
            !(closeEdge.source.startsWith('QQ') && closeEdge.target.startsWith('QQ')) &&
            !nextEdges.find(ne => ne.source === closeEdge.source && ne.target === closeEdge.target)
          ) {
            closeEdge.className = 'temp';

            nextEdges.push(closeEdge);
          }

          return nextEdges;
        });
      }
    },
    [getClosestEdge, setEdges, nodes, edges],
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      const closeEdge = getClosestEdge(node);
      const currentEdgesCopy = [...edges];
      const currentNodesCopy = [...nodes];
      const nextEdges = currentEdgesCopy.filter(e => e.className !== 'temp');

      if (closeEdge) {
        const parentObj: any = nodes.filter((currentNode: any) => currentNode.id === closeEdge.source)[0];
        const childObj: any = nodes.filter((currentNode2: any) => currentNode2.id === closeEdge.target)[0];

        if (
          closeEdge &&
          closeEdge.target !== firstQuestion &&
          !lastAnswers.includes(closeEdge.source) &&
          !(closeEdge.target.startsWith('QA') && (closeEdge.source.startsWith('QA') || childObj.parent)) &&
          !(closeEdge.source.startsWith('QQ') && closeEdge.target.startsWith('QQ')) &&
          !nextEdges.find(ne => ne.source === closeEdge.source && ne.target === closeEdge.target)
        ) {
          nextEdges.push(closeEdge);

          if (parentObj.children) {
            parentObj.children.push(closeEdge.target);
          } else {
            parentObj.children = [closeEdge.target];
          }

          const updatedChildObj = {
            ...childObj,
            parent: closeEdge.source,
          };
          let text;
          const updatedNodes = currentNodesCopy.map((nodeElement: any) => {
            if (nodeElement.id === closeEdge.target) {
              return parentObj;
            }
            if (nodeElement.id === closeEdge.source) {
              text = nodeElement.text;
              return updatedChildObj;
            }
            return nodeElement;
          });

          setNodes(updatedNodes);
          setEdges(nextEdges);

          const newParentID = closeEdge.source;
          const nodeID = closeEdge.target;

          if (newParentID.startsWith('QA')) {
            if (
              !Object.keys(changedNodes[selectedProductCategory]).length ||
              !Object.keys(changedNodes[selectedProductCategory]).includes(newParentID)
            ) {
              const foundKey = Object.keys(scoreMatrix.scores).find(key => {
                const answersObj = scoreMatrix.scores[key];
                const answerArray = Object.keys(answersObj);
                return answerArray.includes(newParentID);
              });

              const initializedArray = scoreMatrix.scores[foundKey][newParentID];
              dispatch(
                addNewNodeToMatrix({
                  addedChildren: nodeID,
                  id: newParentID,
                  initializedArray,
                  parentID: foundKey,
                  selectedProductCategory,
                  text,
                }),
              );
            } else {
              dispatch(updateNodeState({ addedChildren: nodeID, id: newParentID, selectedProductCategory }));
            }
          } else if (newParentID.startsWith('QQ')) {
            if (
              !Object.keys(changedNodes[selectedProductCategory]).length ||
              !Object.keys(changedNodes[selectedProductCategory]).includes(newParentID)
            ) {
              dispatch(
                addNewNodeToMatrix({
                  addedChildren: nodeID,
                  id: newParentID,
                  selectedProductCategory,
                  text,
                }),
              );
            } else {
              dispatch(updateNodeState({ addedChildren: nodeID, id: newParentID, selectedProductCategory }));
            }
          }
        }
      }
    },
    [getClosestEdge, nodes, edges],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const resetInput = () => {
    setInputValue('');
  };
  const handleKeyPressNode = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const { scores } = scoreMatrix;
      const questionKeys = Object.keys(scores);
      let isExisting = false;
      questionKeys.forEach(key => {
        if (inputValue === key) {
          isExisting = true;
        }
        const answers = scores[key];

        if (Object.keys(answers).includes(inputValue)) {
          isExisting = true;
        }
      });
      if (isExisting) {
        setAddNodeOpen(false);
        setInputValue('');
        setNodes(prevNodes => {
          return [
            ...prevNodes,
            {
              addedLater: true,
              data: { label: inputValue },
              id: inputValue,
              position: { x: 0, y: 0 },
              type: 'custom',
            },
          ];
        });
      }
    }
  };

  const isReady = CheckIfReadyToBeDownloaded();

  const getNodeInfo = (id, text, scoresObj) => {
    setNodes(prevNodes => {
      return [
        ...prevNodes,
        {
          data: { label: id, text },
          id,
          position: { x: 0, y: 0 },
          text,
          type: 'custom',
        },
      ];
    });

    if (id.startsWith('QA')) {
      const { length } = scoreMatrix.characters;
      const initializedArray = Array.from({ length }, () => 0);
      if (Object.keys(scoresObj).length) {
        for (let i = 0; i < Object.keys(scoresObj).length; i += 1) {
          initializedArray[Object.keys(scoresObj)[i]] = Object.values(scoresObj)[i];
        }
      }
      dispatch(addNewNodeToMatrix({ id, initializedArray, isNewNode: true, selectedProductCategory, text }));
    } else {
      dispatch(addNewNodeToMatrix({ id, isNewNode: true, selectedProductCategory, text }));
    }
  };

  const getFormCloseInfo = () => {
    setCreateNodeOpen(false);
  };

  const closeFormUpdate = () => {
    setShowForm(false);
  };

  const handleClose = () => {
    if (CheckIfReadyToBeDownloaded()) {
      getCurrentNodeEdgeInfo(nodes, edges);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            alignItems: 'center',
            background: `${themeDevTools.color.black}`,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: '10px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Button
            size="small"
            style={{ backgroundColor: 'teal', color: 'white', fontSize: '0.50rem', padding: '6px 12px' }}
            variant="outlined"
            onClick={() => {
              onLayout('TB');
            }}
          >
            Vertical Layout
          </Button>
          <Button
            size="small"
            style={{ backgroundColor: 'teal', color: 'white', fontSize: '0.50rem', margin: '5px', padding: '6px 12px' }}
            variant="outlined"
            onClick={() => {
              onLayout('LR');
            }}
          >
            Horizontal Layout
          </Button>
          <Button
            size="small"
            style={{ backgroundColor: 'gray', color: 'white', fontSize: '0.50rem', margin: '5px', padding: '6px 12px' }}
            onClick={() => setCreateNodeOpen(true)}
          >
            Create Node
          </Button>
          <Button
            size="small"
            style={{ backgroundColor: 'gray', color: 'white', fontSize: '0.50rem', margin: '5px', padding: '6px 12px' }}
            onClick={() => setAddNodeOpen(true)}
          >
            Add Node
          </Button>
          {isReady ? (
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: 'purple',
                color: 'white',
                fontSize: '0.50rem',
                margin: '5px',
                padding: '6px 12px',
              }}
              onClick={handleClose}
            >
              SAVE CHANGES
            </Button>
          ) : (
            <Alert severity="error" style={{ margin: '5px' }}>
              There are disconnected nodes/edges
            </Alert>
          )}
        </div>
        {showForm && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                background: 'white',
                border: '10px solid grey',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ background: 'white', color: 'black', margin: 8 }}> UPDATE NODE</div>
              <div style={{ display: 'flex', height: '150px', overflow: 'scroll', width: '360px' }}>
                <div>
                  <NodeInfoForm
                    charactersArray={charactersList}
                    closeFormUpdate={closeFormUpdate}
                    prevText={clickedNodeText}
                    scoresArray={clickedNodeScores}
                    selectedNode={clickedNode}
                    updateNode={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {addNodeOpen && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                background: 'white',
                border: '5px solid grey',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormControl sx={{ minWidth: 180 }}>
                <div style={{ color: 'black', display: 'flex', height: 35, justifyContent: 'space-evenly' }}>
                  {' '}
                  ADD NEW NODE
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    id="add-node"
                    label="Question/Answer ID"
                    size="small"
                    value={inputValue}
                    variant="outlined"
                    onChange={handleInputChange}
                    onClick={resetInput}
                    onKeyDown={handleKeyPressNode}
                  />
                  <Button style={{ color: 'black' }} onClick={() => setAddNodeOpen(false)}>
                    {' '}
                    Add{' '}
                  </Button>
                </div>
              </FormControl>
            </div>
          </div>
        )}
        {createNodeOpen && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
              style={{
                background: 'white',
                border: '5px solid grey',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <NewNodeForm
                charactersArray={scoreMatrix.characters}
                getFormCloseInfo={getFormCloseInfo}
                getNodeInfo={getNodeInfo}
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          alignItems: 'center',
          background: `${themeDevTools.color.black}`,
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <ReactFlow
          fitView
          connectionLineType={ConnectionLineType.SmoothStep}
          defaultViewport={defaultViewPort}
          edges={edges}
          minZoom={0}
          nodeTypes={nodeTypes}
          nodes={nodes}
          onConnect={onConnect}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onEdgesDelete}
          onNodeClick={onNodeClick}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onNodesChange={onNodesChange}
          onNodesDelete={onNodesDelete}
        >
          <Controls style={{ bottom: '60px', left: '10px', position: 'absolute' }} />
        </ReactFlow>
      </div>
    </>
  );
}

export default LayoutFlow;
