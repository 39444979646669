import { createSlice } from '@reduxjs/toolkit';

import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { getProfileCharactersApiUrlCreator } from '@lib/core/users/slices/urls';
import { IUserCharacter } from '@lib/core/users/types';

export interface IUserCharacterSlice {
  data: IUserCharacter[];
  isLoading: boolean;
  error: string;
}

const initialState: IUserCharacterSlice = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetUserCharacters = createTypedAsyncThunk<IUserCharacter[]>(
  'user/actionGetUserCharacters',
  async () =>
    await requestWithKeysAsOptions({
      apiPath: getProfileCharactersApiUrlCreator(),
    }),
);
export const charactersSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetUserCharacters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetUserCharacters.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetUserCharacters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
  initialState,
  name: 'userCharacters',
  reducers: {
    actionResetUserCharactersSlice: () => initialState,
  },
});

export default charactersSlice.reducer;

export const { actionResetUserCharactersSlice } = charactersSlice.actions;
