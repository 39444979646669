import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/atoms/CTA/Button';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';
import QuestionHeader from '@components/pmi/src/organisms/Header/Header';
import HealthWarning from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import ProductReferenceCard from '@components/pmi/src/organisms/Quiz/ProductReferenceCard/ProductReferenceCard';
import AnswerSlider from '@components/pmi/src/organisms/Quiz/QDS8003/AnswerSlider/AnswerSlider';
import QuizFooter from '@components/pmi/src/organisms/Quiz/QuizFooter/QuizFooter';

interface Props {
  answers: TQuizAnswerData[];
  questionTitle: string;
  questionCaption: string;
  isLoading: boolean;
  questionImage: string;
  goBack: (count?: number) => void;
  handleConfirmAnswer: (answerId: string | string[], callback: () => void) => void;
  isShowBackButton: boolean;
  progressPercent: number;
  productCardCaption: string;
  productCardEssenceDescription: string;
  productCardName: string;
  productCardImage: string;
  productCardPrimaryColor: string;
  productCardShowEssenceLink: boolean;
  isPearlProduct: boolean;
  isHeetsBackgroundEnabled: boolean;
  isHealthWarningQuizAddon: boolean;
  currentPrimaryColor: string;
  isTobaccoExperienceHiddenAddon: boolean;
  isShowRestrictedBackground: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

const QuizPage = ({
  answers,
  questionTitle,
  questionCaption,
  isLoading,
  questionImage,
  goBack,
  handleConfirmAnswer,
  isShowBackButton,
  progressPercent,
  productCardCaption,
  productCardEssenceDescription,
  productCardName,
  productCardImage,
  productCardPrimaryColor,
  productCardShowEssenceLink,
  isPearlProduct,
  isHeetsBackgroundEnabled,
  isHealthWarningQuizAddon,
  currentPrimaryColor,
  isTobaccoExperienceHiddenAddon,
  isShowRestrictedBackground,
  isHealthWarningFooterToIsraelAddon,
}: Props) => {
  const goChange = () => {
    goBack(3);
  };

  const productCardLinkText = localeV2.quiz.productReferenceLinkAroma;
  const questionHeaderProductCardDetails = {
    goChange,
    isPearlProduct,
    isTobaccoExperienceHiddenAddon,
    productCardCaption,
    productCardEssenceDescription,
    productCardImage,
    productCardLinkText,
    productCardName,
    productCardPrimaryColor,
    productCardShowEssenceLink,
  };
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const [answersArray, setAnswersArray] = useState<string[]>([]);
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);
  const handleClick = () => {
    handleConfirmAnswer(answersArray, () => {
      setAnswersArray([]);
    });
  };
  const [checkIfAnswerSelected, setCheckIfAnswerSelected] = useState(false);
  const handleSliderSelection = answer => {
    setAnswersArray([answer]);
  };
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      className={`ta-v2-qds8004-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={{
        background: currentBackground,
        paddingBottom: paddingForFooter,
      }}
    >
      <QuestionHeader
        caption={questionCaption}
        currentPrimaryColor={currentPrimaryColor}
        goBack={goBack}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isLoading={isLoading}
        isShowBackButton={isShowBackButton}
        progressPercent={progressPercent}
        questionHeaderProductCardDetails={questionHeaderProductCardDetails}
        title={questionTitle}
      />
      <div className="ta-v2-qds8004-page-answer-section-cover">
        <div className="ta-v2-qds8004-page-answer-section">
          <ProductReferenceCard
            caption={productCardCaption}
            classname="ta-v2-qds8004-product-reference-card"
            essenceDescription={productCardEssenceDescription}
            goChange={goChange}
            image={productCardImage}
            isPearlProduct={isPearlProduct}
            isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
            name={productCardName}
            primaryColor={productCardPrimaryColor}
            productCardLinkText={productCardLinkText}
            showEssenceLink={productCardShowEssenceLink}
          />
          <span className="ta-v2-qds8004-page-answer-section-header">
            <LocaleFragment message={localeV2.quiz.qds8004AnswerSectionHeaderText} />
          </span>
          <div className="ta-v2-qds8004-page-answer-item-container">
            <AnswerSlider
              answers={answers}
              classname="ta-v2-qds8004-page-answer-slider"
              currentPrimaryColor={currentPrimaryColor}
              handleSliderSelection={handleSliderSelection}
              setCheckIfAnswerSelected={setCheckIfAnswerSelected}
              sliderText={<LocaleFragment message={localeV2.quiz.qds8004SliderText} />}
            />
          </div>
          {!isMobileResolution ? (
            <div className="d-flex justify-content-center w-100">
              <CTAButton
                btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
                className="ta-v2-cta-btn-margin-large"
                isDark={checkIfAnswerSelected}
                isDisabled={!checkIfAnswerSelected}
                onBtnClick={handleClick}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        {isMobileResolution ? (
          <QuizFooter handleClick={handleClick} isNextButtonEnabled={checkIfAnswerSelected} />
        ) : null}
        {isHealthWarningQuizAddon && <HealthWarning />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default QuizPage;
