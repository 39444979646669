import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { apiUrlGetEanCodeProduct } from '@lib/core/products/slices/urls';
import { TProductInstance } from '@lib/core/products/types';
import { selectAppliedAddOns } from '@lib/core/retailers/selectors/retailerLocation';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { actionShowFailedScanModal, handleFidelityCodeThunk } from '@lib/core/users/slices/fidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

const checkIfScannedCodeIsEan = async (code: string) => {
  try {
    const { results = [] }: { results: TProductInstance[] } = await request(apiUrlGetEanCodeProduct(code), {
      additionalHeaders: { Accept: 'application/json; version="2.0"' },
    });

    return { isScannedCodeEan: results.length > 0, productData: results[0] };
  } catch (error) {
    return { isScannedCodeEan: false, productData: null };
  }
};

/** User can scan:
 * 1. EAN - product code
 * 2. Fidelity card - loyalty card code
 */
export const scanCode = createTypedAsyncThunk('table/scanCode', async (code: string, { getState, dispatch }) => {
  const { isEnableKioskFidelityScanAddon } = selectAppliedAddOns(getState());
  const productCategory = selectServiceProductCategory(getState());

  // Check if the scanned code is EAN
  const { isScannedCodeEan, productData } = await checkIfScannedCodeIsEan(code);

  // If the code is EAN, handle it and reject the Promise
  if (isScannedCodeEan) {
    history.push(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productData?.identifier }));
    MixpanelTracker.events.scanProduct(productData, code);
    return Promise.reject();
  }

  // If the code isn't EAN and isEnableKioskFidelityScanAddon is off we don't handle Fidelity card and fail the scan
  if (!isEnableKioskFidelityScanAddon) {
    MixpanelTracker.events.scanFailed(code);
    dispatch(actionShowFailedScanModal());
    return Promise.reject();
  }

  // If the code is Fidelity card, handle it
  return dispatch(
    handleFidelityCodeThunk({
      fidelityCardCode: code,
      productCategory,
    }),
  );
});

const initialState = {
  isScanningLoading: false,
};

export const scannerSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(scanCode.pending, state => {
      state.isScanningLoading = true;
    });
    builder.addMatcher(isAnyOf(scanCode.fulfilled, scanCode.rejected), state => {
      state.isScanningLoading = false;
    });
  },
  initialState,
  name: 'scanner',
  reducers: {},
});

export default scannerSlice.reducer;
