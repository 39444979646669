import cn from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';
import { useMediaQuery } from 'react-responsive';

import { handleClick } from '@lib/tools/shared/pmi/quizzes/selectAnswer';
import { useHeight } from '@lib/tools/views/hooks';

interface Props {
  answerText: string;
  answerImage: string;
  className?: string;
  answerId: string;
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  handleConfirmAnswer?: (answerId: string, callback: () => void) => void;
  currentPrimaryColor: string;
  isLight?: boolean;
}

export const AnswerItem: FC<Props> = ({
  answerText,
  answerImage,
  className,
  answerId,
  setAnswersArray,
  answersArray = [],
  maxAnswersChoices,
  handleConfirmAnswer,
  currentPrimaryColor,
  isLight = false,
}) => {
  const [heightRef, height] = useHeight<HTMLDivElement>();
  const answerTextSplit = answerText.split('\n');
  const answerTitle = answerTextSplit[0];
  const answerCaption = answerTextSplit[1] || '';
  const isSelected = answersArray.includes(answerId);
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      ref={heightRef}
      role="button"
      tabIndex={0}
      className={cn('ta-v2-qds8012-answerItem-btn', className, {
        isSelected,
      })}
      onClick={() => handleClick({ answerId, answersArray, handleConfirmAnswer, maxAnswersChoices, setAnswersArray })}
      onKeyDown={() => handleClick({ answerId, answersArray, handleConfirmAnswer, maxAnswersChoices, setAnswersArray })}
    >
      <div
        className="ta-v2-qds8012-answerItem-btn-image-cover"
        style={{ backgroundColor: isSelected && currentPrimaryColor, height }}
      >
        <img alt="answer" className="ta-v2-qds8012-answerItem-btn-image" src={answerImage} />
      </div>
      <div
        style={{ backgroundColor: isSelected && currentPrimaryColor }}
        className={cn('ta-v2-qds8012-answerItem-btn-text-cover', {
          isLight,
        })}
      >
        <span className="font-ta-v2-title-5">{answerTitle}</span>
        <span
          className={`${isMobileResolution ? 'font-ta-v2-global-regulatory-text-small' : 'font-ta-v2-global-body-small'}`}
        >
          {answerCaption}
        </span>
      </div>
    </div>
  );
};
