import styled, { css } from 'styled-components';

import {
  TFontFamilies,
  TFontStyle,
  TStyleWeight,
  TTextAlight,
  TTextDecoration,
  TTextTransform,
  TTypographyVariantSize,
  TTypographyVariants,
} from '@components/web/src/foundations/Text/types';

export const fontFamilies: Record<TFontFamilies, TFontFamilies> = {
  Fraunces: 'Fraunces',
  Montserrat: 'Montserrat',
};

const fontWeights: Record<TStyleWeight, number> = {
  bold: 700,
  medium: 500,
  normal: 400,
  semibold: 600,
};

export const TYPOGRAPHY_SIZES: TTypographyVariantSize = {
  body1: {
    lg: {
      'font-size': '16px',
      'line-height': '24px',
    },
    md: {
      'font-size': '14px',
      'line-height': '18px',
    },
    sm: {
      'font-size': '14px',
      'line-height': '18px',
    },
    xl: {
      'font-size': '16px',
      'line-height': '24px',
    },
  },
  body2: {
    lg: {
      'font-size': '14px',
      'line-height': '20px',
    },
    md: {
      'font-size': '12px',
      'line-height': '16px',
    },
    sm: {
      'font-size': '12px',
      'line-height': '16px',
    },
    xl: {
      'font-size': '14px',
      'line-height': '20px',
    },
  },
  body3: {
    lg: {
      'font-size': '12px',
      'line-height': '18px',
    },
    md: {
      'font-size': '10px',
      'line-height': '14px',
    },
    sm: {
      'font-size': '10px',
      'line-height': '14px',
    },
    xl: {
      'font-size': '12px',
      'line-height': '18px',
    },
  },
  h1: {
    lg: {
      'font-size': '80px',
      'letter-spacing': '-1.6px',
      'line-height': '110px',
    },
    md: {
      'font-size': '60px',
      'letter-spacing': '-1.2px',
      'line-height': '72px',
    },
    sm: {
      'font-size': '48px',
      'letter-spacing': '-0.96px',
      'line-height': '60px',
    },
    xl: {
      'font-size': '80px',
      'letter-spacing': '-1.6px',
      'line-height': '110px',
    },
  },
  h2: {
    lg: {
      'font-size': '60px',
      'letter-spacing': '-1.2px',
      'line-height': '72px',
    },
    md: {
      'font-size': '48px',
      'letter-spacing': '-0.96px',
      'line-height': '60px',
    },
    sm: {
      'font-size': '42px',
      'letter-spacing': '-0.84px',
      'line-height': '48px',
    },
    xl: {
      'font-size': '60px',
      'letter-spacing': '-1.2px',
      'line-height': '72px',
    },
  },
  h3: {
    lg: {
      'font-size': '48px',
      'letter-spacing': '-0.96px',
      'line-height': '60px',
    },
    md: {
      'font-size': '42px',
      'letter-spacing': '-0.84px',
      'line-height': '48px',
    },
    sm: {
      'font-size': '36px',
      'letter-spacing': '-0.72px',
      'line-height': '42px',
    },
    xl: {
      'font-size': '48px',
      'letter-spacing': '-0.96px',
      'line-height': '60px',
    },
  },
  h4: {
    lg: {
      'font-size': '36px',
      'letter-spacing': '-0.72px',
      'line-height': '44px',
    },
    md: {
      'font-size': '36px',
      'letter-spacing': '-0.6px',
      'line-height': '44px',
    },
    sm: {
      'font-size': '30px',
      'letter-spacing': '-0.6px',
      'line-height': '36px',
    },
    xl: {
      'font-size': '40px',
      'letter-spacing': '-0.8px',
      'line-height': '48px',
    },
  },
  h5: {
    lg: {
      'font-size': '30px',
      'line-height': '38px',
    },
    md: {
      'font-size': '24px',
      'line-height': '30px',
    },
    sm: {
      'font-size': '24px',
      'line-height': '30px',
    },
    xl: {
      'font-size': '28px',
      'line-height': '32px',
    },
  },
  h6: {
    lg: {
      'font-size': '24px',
      'line-height': '32px',
    },
    md: {
      'font-size': '20px',
      'line-height': '24px',
    },
    sm: {
      'font-size': '20px',
      'line-height': '24px',
    },
    xl: {
      'font-size': '22px',
      'line-height': '28px',
    },
  },
  subtitle1: {
    lg: {
      'font-size': '20px',
      'line-height': '28px',
    },
    md: {
      'font-size': '18px',
      'line-height': '24px',
    },
    sm: {
      'font-size': '18px',
      'line-height': '24px',
    },
    xl: {
      'font-size': '18px',
      'line-height': '28px',
    },
  },
  subtitle2: {
    lg: {
      'font-size': '18px',
      'line-height': '28px',
    },
    md: {
      'font-size': '16px',
      'line-height': '20px',
    },
    sm: {
      'font-size': '16px',
      'line-height': '20px',
    },
    xl: {
      'font-size': '18px',
      'line-height': '28px',
    },
  },
};

export const generateTypographyStyles = (
  fontSize = 'body3',
  fontFamily = fontFamilies.Montserrat,
  fontWeight = 'normal',
  isKioskRootElementType,
) => {
  if (isKioskRootElementType) {
    return css`
      font-family: ${fontFamily};
      font-weight: ${fontWeights[fontWeight]};
      font-size: ${TYPOGRAPHY_SIZES[fontSize].xl['font-size']};
      line-height: ${TYPOGRAPHY_SIZES[fontSize].xl['line-height']};
      letter-spacing: ${TYPOGRAPHY_SIZES[fontSize].xl?.['letter-spacing'] || 'normal'};
    `;
  }
  return css`
    font-family: ${fontFamily};
    font-weight: ${fontWeights[fontWeight]};
    font-size: ${TYPOGRAPHY_SIZES[fontSize].sm['font-size']};
    line-height: ${TYPOGRAPHY_SIZES[fontSize].sm['line-height']};
    letter-spacing: ${TYPOGRAPHY_SIZES[fontSize].sm?.['letter-spacing'] || 'normal'};
  `;
};

export const StyledText = styled.span<{
  $size?: TTypographyVariants;
  $weight?: TStyleWeight;
  $color?: string;
  $fontFamily?: TFontFamilies;
  $linesLimit?: number;
  $textDecoration?: TTextDecoration;
  $fontStyle?: TFontStyle;
  $textAlign?: TTextAlight;
  $textTransform?: TTextTransform;
  $strongColor?: string;
  $strongSize?: TTypographyVariants;
  $strongWeight?: TStyleWeight;
}>`
  p {
    margin: 0;
  }

  color: ${({ $color = 'inherit' }) => $color};

  font-style: ${({ $fontStyle = 'normal' }) => $fontStyle};
  text-decoration: ${({ $textDecoration = 'none' }) => $textDecoration};
  text-align: ${({ $textAlign = 'unset' }) => $textAlign};
  text-transform: ${({ $textTransform = 'none' }) => $textTransform};

  ${({ $size, $weight, $fontFamily, theme }) =>
    generateTypographyStyles($size, $fontFamily || fontFamilies.Montserrat, $weight, theme?.isKioskRootElementType)}

  ${({ $linesLimit }) =>
    $linesLimit &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;

      display: -webkit-box !important;
      -webkit-line-clamp: ${$linesLimit};
      -webkit-box-orient: vertical;
      white-space: normal;
    `}
  ${({ $textDecoration }) =>
    $textDecoration &&
    css`
      text-decoration: ${$textDecoration};
    `}
  strong {
    color: ${({ $strongColor = 'inherit' }) => $strongColor};
    ${({ $strongSize, $strongWeight, $fontFamily, theme }) =>
      generateTypographyStyles(
        $strongSize,
        $fontFamily || fontFamilies.Montserrat,
        $strongWeight,
        theme?.isKioskRootElementType,
      )}
  }
`;
