import { useSelector } from 'react-redux';

import {
  selectQuizQuestionImages,
  selectUserQuizCharacters,
  selectUserQuizCompleted,
  selectUserQuizId,
  selectUserQuizLoading,
  selectUserQuizSlug,
  selectUserQuizState,
  selectUserQuizType,
} from '@lib/core/quizzes/selectors';

export const useUserQuiz = () => {
  return {
    isUserQuizComplete: useSelector(selectUserQuizCompleted),
    isUserQuizLoading: useSelector(selectUserQuizLoading),
    userQuizCharacters: useSelector(selectUserQuizCharacters),
    userQuizId: useSelector(selectUserQuizId),
    userQuizQuestionImages: useSelector(selectQuizQuestionImages),
    userQuizSlug: useSelector(selectUserQuizSlug),
    userQuizState: useSelector(selectUserQuizState),
    ...useSelector(selectUserQuizType),
  };
};
