import { useSelector } from 'react-redux';

import {
  selectCharacters,
  selectCharactersByCharacterType,
  selectIsCharactersLoading,
} from '@lib/core/characters/selectors';

export const useCharacters = () => ({
  characters: useSelector(selectCharacters),
  charactersByCharacterType: useSelector(selectCharactersByCharacterType),
  isCharactersLoading: useSelector(selectIsCharactersLoading),
});
