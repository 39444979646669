import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { broadcastDomHeightToHost } from '@app/pmi/src/comms/utils';

import { selectCharacters } from '@lib/core/characters/selectors';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { useProducts } from '@lib/core/products/hooks/products';
import { actionGetAllProducts } from '@lib/core/products/slices/products';
import { useQuiz } from '@lib/core/quizzes/hooks';
import { actionPatchUserQuizComplete, actionPatchUserQuizSubmitAnswer } from '@lib/core/quizzes/slices';
import QuizLauncher from '@lib/core/quizzes/views/QuizLauncher';
import { prependBasename } from '@lib/core/service/utils';
import { POST_MESSAGE_QUIZ_COMPLETED } from '@lib/tools/comms/consts';
import { sendPostMessage } from '@lib/tools/comms/utils';
import { isProductCrafted } from '@lib/tools/shared/pmi/products/filters';
import { PAGES } from '@lib/tools/views/urls';

import QuizPage from '@components/pmi/src/botanicals/templates/Quiz/QDS/QuizPage';
import Spinner from '@components/pmi/src/common/Spinner';

import quizBg from 'assets/media/crafted/background_cover.png';
import logo from 'assets/media/crafted/botanicals_landing_logo.svg';

/**
 * * Crafted Quiz Structure
 * QuestionID AnswerID    ITCC01S01 ITCC02S01 ITCC03S01
 * QQ15206010	QA15206011  0.4       0.3	      2.2
 * QQ15206010	QA15206012  2.4       2.3	      0.2
 * QQ15206010	QA15206013  1.4       0.3	      0.2
 * QQ15206020	QA15206021  0.4       1.3	      2.2
 * QQ15206020	QA15206022  1.4       0.3	      2.2
 * QQ15206020	QA15206023  1.4       0.3	      0.2
 * QQ15206030	QA15206031  0.4       0.3	      2.2
 * QQ15206030	QA15206032  1.4       2.3	      0.2
 * QQ15206030	QA15206033  3.4       4.3	      0.2
 *
 * * Behavior
 * - The 3 products have 3 unique characters associated to them
 * - The score matrix is used to sum the character scores and to set the final product with the max score
 * - Change the active index of a product changes the final character
 * - The userQuiz answers are recorded and completed after all three gears have been selected and submitted
 */

const QuizCraftedContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productsList } = useProducts();
  const { quizData } = useQuiz();

  const characters = useSelector(selectCharacters);
  const [resultCharacter, setResultCharacter] = useState<IParsedCharacter>(null);

  const scoreMatrix = {
    QA15206011: {
      ITCC01S01: 0.4,
      ITCC02S01: 0.3,
      ITCC03S01: 2.2,
    },
    QA15206012: {
      ITCC01S01: 2.4,
      ITCC02S01: 2.3,
      ITCC03S01: 0.2,
    },
    QA15206013: {
      ITCC01S01: 1.4,
      ITCC02S01: 0.3,
      ITCC03S01: 0.2,
    },
    QA15206021: {
      ITCC01S01: 0.4,
      ITCC02S01: 1.3,
      ITCC03S01: 2.2,
    },
    QA15206022: {
      ITCC01S01: 1.4,
      ITCC02S01: 0.3,
      ITCC03S01: 2.2,
    },
    QA15206023: {
      ITCC01S01: 1.4,
      ITCC02S01: 0.3,
      ITCC03S01: 0.2,
    },
    QA15206031: {
      ITCC01S01: 0.4,
      ITCC02S01: 0.3,
      ITCC03S01: 2.2,
    },
    QA15206032: {
      ITCC01S01: 1.4,
      ITCC02S01: 2.3,
      ITCC03S01: 0.2,
    },
    QA15206033: {
      ITCC01S01: 3.4,
      ITCC02S01: 4.3,
      ITCC03S01: 0.2,
    },
  };

  const handleConfirmAnswers = answerIds => {
    const questionIds = Object.keys(quizData);

    /**
     * Sum the quiz answer scores for each answer to compute the final character
     */
    const sumAnswerScoresByKey = (...scores) => {
      return scores.reduce((a, b) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const k in b) {
          // eslint-disable-next-line no-prototype-builtins
          if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
        }
        return a;
      }, {});
    };

    const characterScores = sumAnswerScoresByKey(
      scoreMatrix[answerIds[0]],
      scoreMatrix[answerIds[1]],
      scoreMatrix[answerIds[2]],
    );

    const maxCharacterScore = Math.max(...Object.values(characterScores).map(Number));
    const resultCharacterId = Object.keys(characterScores)[Object.values(characterScores).indexOf(maxCharacterScore)];

    const character = characters.find(c => c.characterId === resultCharacterId);

    setResultCharacter(character);

    questionIds.forEach((questionId, index) => {
      dispatch(actionPatchUserQuizSubmitAnswer({ answerIds: [answerIds[index]], questionId }));
    });

    dispatch(actionPatchUserQuizComplete({}));
  };

  useEffect(() => {
    dispatch(actionGetAllProducts());
  }, []);

  useEffect(() => {
    broadcastDomHeightToHost();
  }, [productsList]);

  if (characters.length && productsList.length) {
    return (
      <QuizPage
        gprls={productsList.filter(isProductCrafted)}
        handleConfirmAnswers={handleConfirmAnswers}
        handleProductCharacter={setResultCharacter}
        productLogo={logo}
        questionImage={quizBg}
        quizData={quizData}
        goBack={() => {
          navigate(prependBasename(PAGES.ta.landing));
        }}
        handleGoToResult={() => {
          if (productsList.length && resultCharacter) {
            const payload = {};

            const isResultProduct = gprl => gprl.product.character.characterId === resultCharacter.characterId;

            const craftedProducts = productsList.filter(isProductCrafted);
            const resultProduct = craftedProducts.find(isResultProduct);

            /**
             * @todo consider moving to comms provider.
             */
            payload[POST_MESSAGE_QUIZ_COMPLETED] = {
              character: resultCharacter,
              products: [resultProduct, ...craftedProducts.filter(gprl => !isResultProduct(gprl))],
            };

            sendPostMessage(payload);
          }
        }}
      />
    );
  }

  return <Spinner />;
};

export default QuizLauncher(QuizCraftedContainer);
