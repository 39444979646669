import cn from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';

import { handleClick } from '@lib/tools/shared/pmi/quizzes/selectAnswer';

interface Props {
  isWideSize?: boolean;
  answerText: string;
  answerImage: string;
  className?: string;
  answerId: string;
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  handleConfirmAnswer?: (answerId: string, callback: () => void) => void;
  currentPrimaryColor: string;
  isLight?: boolean;
}

const AnswerItem: FC<Props> = ({
  answerText,
  answerImage,
  className,
  answerId,
  setAnswersArray,
  answersArray = [],
  maxAnswersChoices,
  isWideSize,
  handleConfirmAnswer,
  currentPrimaryColor,
  isLight = false,
}) => {
  const isSelected = answersArray.includes(answerId);

  return (
    <button
      className={cn('ta-v2-qds8002-answerItem-btn', className, {
        isSelected,
        'ta-v2-qds8002-answerItem-btn-wide': isWideSize,
      })}
      onClick={() => handleClick({ answerId, answersArray, handleConfirmAnswer, maxAnswersChoices, setAnswersArray })}
    >
      <div
        className="ta-v2-qds8002-answerItem-btn-image-cover"
        style={{ backgroundColor: isSelected && currentPrimaryColor }}
      >
        <img alt="answer" className="ta-v2-qds8002-answerItem-btn-image" src={answerImage} />
      </div>
      <div
        style={{ backgroundColor: isSelected && currentPrimaryColor }}
        className={cn('ta-v2-qds8002-answerItem-btn-text-cover font-ta-v2-global-body-large', {
          isLight,
        })}
      >
        <span>{answerText}</span>
      </div>
    </button>
  );
};

export default AnswerItem;
