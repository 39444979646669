import { createSlice } from '@reduxjs/toolkit';

import {
  apiUrlGetCharacterEssences,
  apiUrlGetCharacterFoodPairing,
  apiUrlGetRetailerCharacters,
} from '@lib/core/characters/slices/urls';
import { ICharacterListItem, IFoodPairing, TCharacter } from '@lib/core/characters/types';
import { TEssence } from '@lib/core/characters/types/essences';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';

export interface IRetailerCharactersSlice {
  data: TCharacter[];
  isLoading: boolean;
}

export const initialState: IRetailerCharactersSlice = {
  data: [],
  isLoading: false,
};

export const actionGetRetailerCharacterDetails = createTypedAsyncThunk<TCharacter, { characterId: string }>(
  'characters/actionGetRetailerCharacterDetails',
  async ({ characterId }) =>
    requestWithKeysAsOptions({
      apiPath: apiUrlGetRetailerCharacters({ characterId }),
    }),
);

export const actionGetRetailerCharacters = createTypedAsyncThunk<{ results: ICharacterListItem[] }, void>(
  'characters/actionGetRetailerCharacters',
  async () =>
    requestWithKeysAsOptions({
      apiPath: apiUrlGetRetailerCharacters({}),
    }),
);

export const actionGetRetailerCharacterEssences = createTypedAsyncThunk<
  { results: TEssence[] },
  { characterId: string }
>('characters/actionGetRetailerCharacterEssences', async ({ characterId }) =>
  requestWithKeysAsOptions({
    apiPath: apiUrlGetCharacterEssences({ characterId }),
  }),
);

export const actionGetRetailerCharacterFoodPairing = createTypedAsyncThunk<IFoodPairing, { characterId: string }>(
  'characters/actionGetRetailerCharacterFoodPairing',
  async ({ characterId }) => {
    const {
      results: [foodPairing],
    } = await requestWithKeysAsOptions({
      apiPath: apiUrlGetCharacterFoodPairing({ characterId }),
    });
    return foodPairing;
  },
);

export const index = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetRetailerCharacterDetails.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetRetailerCharacterDetails.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetRetailerCharacterDetails.fulfilled, (state, action) => {
      const { meta: { arg: { characterId } = {} } = {}, payload } = action;
      state.isLoading = false;
      state.data = state.data.map(character =>
        character.identifier === characterId ? { ...character, ...payload } : character,
      );
    });
    builder.addCase(actionGetRetailerCharacters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetRetailerCharacters.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetRetailerCharacters.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload: { results } = {} } = action;

      // App speed optimization feature, that means that details or essences requests for top user character can be
      // loaded before this large request and not be erased by it later (aroma,attributes,essences)
      state.data = results.map(character => {
        const storedCharacterData = state.data.find(
          storedCharacter => storedCharacter.identifier === character.identifier,
        );
        return {
          ...storedCharacterData,
          ...character,
        };
      });
    });
    builder.addCase(actionGetRetailerCharacterEssences.fulfilled, (state, action) => {
      const {
        payload: { results } = {},
        meta: {
          arg: { characterId },
        },
      } = action;
      state.data = state.data.map(character =>
        character.identifier === characterId ? { ...character, essences: results } : character,
      );
    });
    builder.addCase(actionGetRetailerCharacterFoodPairing.fulfilled, (state, action) => {
      state.isLoading = false;
      const {
        payload,
        meta: {
          arg: { characterId },
        },
      } = action;
      state.data = state.data.map(character =>
        character.identifier === characterId ? { ...character, foodPairing: payload } : character,
      );
    });
  },
  initialState,
  name: 'characters',
  reducers: {},
});

export default index.reducer;
